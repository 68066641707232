.checkbox-button-series {
    margin-top: 2em;
    display: none;

    span {
        display: block;
        margin-bottom: .5em;
    }

    .radio-btn {
        font-size: 1em;
        font-weight: normal;
        margin-right: 1.2em;
        width: 20px;
    }
}