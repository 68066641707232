
$card-spacing: 1.3em;
$card-padding: 12px; //using pixels to allow to space elements along the edge
$card-boxshaddow:  0px 1px 4px rgba(0,0,0,0.3);
$card-background: $base-lightest;
$card-font: $font-primary;
$card-font-headings: $font-secondary;
$card-heading-color: $brand-tertiary;

//general card styles
.card{
	@include clear;

	background-color:$card-background;
	box-shadow:$card-boxshaddow;
	margin-top:$card-spacing;
	margin-bottom:$card-spacing;
	padding:$card-padding;

	font-size:0.9em;
	position: relative;
	width:100%;


	a:link,
	a:visited{
		text-decoration: none;

		.card-content .card-text{
			color:black;
		}
	}

	a:hover,
	a:active,
	a:focus{
		text-decoration: underline;

		.card-heading{
			text-decoration: underline;
		}
		//highlights when double links ared used
		+ .card-content .card-heading{
			text-decoration: underline;
		}
		
	}

	> a:hover,
	> a:active,
	> a:focus{
		//direct links of cards should not have an underline
		//or all content inside will have that underline
		text-decoration: none;
	}
	
	p{
		margin-bottom:0.5em;
		&:last-child{
			margin-bottom:0;
		}
	}

	> :last-child{
		margin-bottom:0 !important;
	}

	.card-gauge-alarm-status {
		display: none;
		width: 100%;
		height: 5px;
		background-color: red;
	}

	.card-gauge-alarm-status.visible {
		display: block;
	}

}


.card-link{
	display:block;
	background-color:$card-background;
	box-shadow: $card-boxshaddow;
	padding:11px 16px;
	font-size: 1.2em;
    font-weight: 400;
	line-height:1.1;


	&:link,
	&:visited{
		text-decoration: none;
		color:$brand-tertiary;

	}

	&:hover,
	&:active,
	&:focus{
		text-decoration: underline;
		color: darken($brand-tertiary, 20%);
	}
}


.card .card-heading{
	font-size: 1.45em;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.25em;
    font-family: $card-font-headings;
    color :$brand-tertiary;


	a:link,
	a:visited{
		color:$base-darkest;
	}

	a:hover,
	a:active,
	a:focus{
		color: darken($base-darkest, 20%);
	}
	@media screen and (min-width: $font-bp-one){
		font-size:1.6em;
		margin-bottom: 0.4em;

	}
}

.card .card-subheading{
	font-size: 1.2em;
    font-weight: 900;
    line-height: 1.2;
    font-family: $card-font-headings;
    margin-bottom:0.2em;
    color: $card-heading-color;
}

.card-image-cont img{

}

.card .card-image{
	width:100%;
	max-width:none;
	margin-bottom:0.4em;
}




.card.card-image-background{
	position: relative;
	display:flex; 
	align-items: center;
	justify-content: center;
	padding:0;
	width: 100%;

	.btn.btn-outline{
		background-color:transparent;
		border: 1px solid white;
		color:white;
		font-size:0.9em;
		margin-top:0.6em;
		    padding: 0.4em 1em;


		@media screen and (max-width: 600px){
			max-width:85%;
		}

		&:hover,
		&:active,
		&:focus{
			background-color: rgba(white, 0.8);
			color:black;
			text-decoration: none;
		}
	}
	.card-image{
		position: absolute;
		top:0;
		left:0;
		object-fit: cover;
		font-family: 'object-fit: cover;';
		width:100%;
		height:100%;
		z-index: 1;

	}

	&:before{
		content: '';
		display:block;
		position: absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		background-color:rgba($brand-tertiary, 0.6);
		z-index: 2;

	}


	.card-content{
		padding-left: $wrap-small;
    	padding-right:$wrap-small;
    	padding-top:4em;
    	padding-bottom: 4em;
    	position: relative;
    	z-index: 2;
    	text-align: center;
	}

	.card-heading,
	.card-subheading,
	.card-text{
		color:white;
	}

	.card-heading{
		font-weight: 900;
		font-family: $card-font-headings;
		font-size: 1.6em;
	}



	.card-subheading{
		text-transform: uppercase;
		font-size:0.85em;
		
	}

	a:link,
	a:visited{
		color: white;
	}

	a:active,
	a:hover,
	a:focus{
		color: white;
	}

	@media screen and (min-width: 900px){
		min-height: 274px;
	}

	@media screen and (min-width: 1300px){
		font-size:1.1em;
		min-height: 374px;
		padding-left: 2em;
    	padding-right:2em;
		
	}
}

.card.card-image-left{
	@media screen and (min-width: 390px) and (max-width: $bp-sm - 1px){
		.card-image{
			float:left;
			margin-bottom:0;
			width:47.5%;
			display:block;
		}
		.card-content{
			width:52.5%;
			float:left;
			padding-left:1.35em;
		}
		.card-heading{
			clear:none;
		}
	}
}

.card.card-dam{
	@include clear;
	.card-gauge-graph{
		width:55%;
		
		float:left;
		padding-left: 4.7em;
		max-width:175px;
	}
	.card-gauge-right{
		width: 65%;
		float: left;
		text-align: center;
		padding-top: 0;
		padding-right: 3em;
		.guage-reading{
			margin-top:0;
			> :first-child{
				margin-top:0;

			}
		}

		.guage-reading-item{
			float:none;
			margin-right:0;
			margin-bottom:1em;
			position: static !important;

		}

		.gauge-reading-value{
			display: inline;
			font-size:1.3em;
		}

		.gauge-reading-rate-of-change {
			display: inline-block;
			padding-left: 10px;
		}
	}
}

