
.document-category {

    margin-bottom: 2.5em;
        
    .document-category-title{
        //margin-bottom:2em;
    }

    .documents-container{
        @include make_row;
    }

    .document {
        margin-bottom: 2em;

        @include make_column(12);
    
        @media screen and (min-width: $bp-md) and (max-width: $bp-lg - 1px ){
            @include make_column(6);
            padding-right:3em;
            &:nth-child(2n+3){
                clear:left;
            }
        }

        @media screen and (min-width: $bp-lg ){
            @include make_column(4);
            padding-right:3em;
            &:nth-child(3n+4){
                clear:left;
            }
        }


    }

    .document-title{
        margin-top:0;
        margin-bottom:0.7em;
    }
}
