.menu-buttons{

	background-color:$base-darkest;
	margin: 0;
	padding: 0;
	list-style:none;

	font-family: $font-secondary;
	font-weight:700;

	text-transform: uppercase;
	
	font-size:0.95em;

	padding-top:0.9em;
	padding-bottom:0.9em;
	padding-left:1em;
	padding-right:1em;


	.menu-item-wrap{
		margin:0;

	}

	.menu-item,
	.menu-item:link,
	.menu-item:visited,
	.menu-item:hover,
	.menu-item:active{

		padding-left:$wrap-small;
		padding-right:$wrap-small;
		padding-top:0.8em;
		padding-bottom:0.8em;
		display:block;
		width:100%;


		background-color:transparent;

		color: $base-light;
		font-size:1em;
		text-align: left;
		border-radius: 0px;
		transition: color $trans-out ease-out, background-color $trans-out ease-out;
	}

	.menu-item:hover,
	.menu-item:active,
	.menu-item:focus{
		background-color: $base-color;
		background-color: $base-light;
		color: $base-darkest;
		text-decoration: none;
		transition: color $trans-in ease-out, background-color $trans-in ease-out;

		.icon{
			stroke: $base-darkest;
			fill:  $base-darkest;
		}
	
	}




	.menu-item-has-icon {
		cursor: pointer;
		.menu-item{
			position: relative;
			padding-left:3.3em;
		}
		.icon{
			stroke: $base-light;
			fill:  $base-light;
			font-size:1.4em;
			position: absolute;
			left:$wrap-small - 2px;
			top:0.6em;
		}
	}

	.menu-item-extra-spacing{
		margin-top:0.8em;
	}



	@media screen and (min-width: $menu-go-big) {

		font-size:0.8em;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		padding-left:0;
		padding-right:0;



		.menu-item-wrap{
			display:block;
			flex: 1; 
			text-align: center;
			padding:0;
			max-width:140px;
		}

		.menu-item-has-icon{
			position: static;
			.menu-item{

				padding:28px 16px 0px 16px;
			}
			.icon{
				position: static;
				display:block;
				width:1.3em;
				height:1.3em;
				margin-bottom:0.5em;
				margin-right:auto;
				margin-left:auto;
			}
		}

		.menu-item,
		.menu-item:link,
		.menu-item:visited,
		.menu-item:hover,
		.menu-item:active,
		.menu-item:focus{
				display:flex;
				padding:28px 16px 0px 16px;
				height:100%;
				width:100%;
				text-align: center;
				align-items:center;
				justify-content: start;
				flex-direction: column;
				text-decoration: none;
				transition: color $trans-out ease-out, background-color $trans-out ease-out;



		}

		.menu-item:hover,
		.menu-item:active
		.menu-item:focus{
			background-color: $base-light;
			color: $base-darkest;
			text-decoration: none;
			transition: color $trans-in ease-out, background-color $trans-in ease-out;

			.icon{
				stroke: $base-darkest;
				fill:  $base-darkest;
			}
		}

		.menu-item-extra-spacing{
			margin-top:0;
			margin-left:2em;
		}
	}

	 

}