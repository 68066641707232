@charset "UTF-8";

/*--------------------------------------------------------------
5.1 Links
--------------------------------------------------------------*/
a {
	color: $link-normal;
	font-family: $font-primary;
	transition: color $trans-out ease-out;
	
	//handle the icons inside of links
	.icon{
		fill:  $link-normal;
		transition:fill $trans-out ease-out;
	}

				
}
a:visited {
	color: $link-normal;
	
}
a:hover,
a:focus,
a:active {
	color: $link-hover;
	
	transition: color $trans-in ease-out;
	
	//handle the icons inside of links
	.icon{
		fill:  $link-hover;
		transition: fill $trans-in ease-out;
	}

}

a.more:link,
a.more:visited,
a.more:hover,
a.more:active{
	text-transform: uppercase;
}

