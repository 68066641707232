.selected-gauge-list{
	list-style:none;
	margin-left:0;
	padding-left:0;

	ul{
		list-style:none;
		margin-left:0;
		padding-left:0;
	}

	.selected-gauge-list-heading{
		text-transform: capitalize;
		display:block;
		margin-bottom:0.6em;
	}

	.selected-guage-list-item{

		display: inline-block;
		background-color: #ffd87b;
		font-size:0.7em;
		line-height: 1em;
		vertical-align: middle;
		position: relative;
		padding:1em 2em 1em 1.3em;
		border-radius: 0.5em;
		margin-right:2em;
		text-transform: uppercase;

				
		.selected-gauge-list-remove{
			max-width:none;
			min-width:0;

			background-color:white;
			position:absolute;
			padding:0;
			right:-0.9em;
			height:1.7em;
			width:1.8em;
			top:0;
			bottom:0;
			margin:auto;

			&:hover,
			&:focus,
			&:active{
				background-color: $brand-primary;
			}
	

			svg{
				height:1em;
				width:1em;
				fill:black;
				display:inline-block;
				position:relative;
				top:0.2em;
			}
		}

		 &.gauge-outside-range{
			 //background-color:$btn-out-of-range-color; ***disabled until phase 2 as per client request***
			 background-color: #ffd87b;
		 }
	}


}


.selected-gauge-list-item-has-tooltip{
	position: relative;
	
	&.show-tooltip .selected-gauge-list-item-tooltip{
		display:block;
	}

	.selected-gauge-list-item-tooltip{
		display:none;
		position: absolute;
		top:100%;
		margin-top:1em;
		background-color:white;
		padding:1em;
		width: 250px;
		left:0;
	   	// left: -9001px;
	    //right: -9001px;
	    margin-left: auto;
	    margin-right: auto;
	    text-transform: none;
	    z-index: 1;

	    .icon{
	    	width:2em;
	    	height:2em;
	    	position: absolute;
	    	left:1em;
	    	top:-1.5em;
	    	fill: white;
	    	stroke: none;

	    }

	}

	.gauge-outside-range-title,
	.gauge-outside-range-times{
		display:block;

		.outside-range{
			//color:$validation-color;
			color: $base-darkest;
		}
	}

	.gauge-outside-range-title{
		margin-bottom:0.6em;
	}

	.gauge-outside-time-title{
		margin-bottom:0.3em;
	}
}
