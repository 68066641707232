.widget-container {
    position: relative;
    left: 20px;
    top: -75px;

    .widget-icon {
        width: 120px;
        height: 125px;
        border: 1px solid #95989A;
        padding: 5px; 
        margin-left: 25px;
    
        .large-box {
            width: 96px;
            height: 48px;
            margin: 5px; 
            border: 1px solid #95989A;
        }

        .medium-box {
            float: left;
            width: 57px;
            height: 32px;
            margin: 5px; 
            border: 1px solid #95989A;
        }
    
        .small-box {
            float: left;
            width: 27px;
            height: 32px;
            margin: 5px; 
            border: 1px solid #95989A;
        }

        .large-box.highlighted,
        .medium-box.highlighted,
        .small-box.highlighted {
            background-color: #95989A;
        }
    }

    span {
        width: 130px;
        color: #000; 
        text-align: center;
        font-size: 1em;
        margin-left: 20px;
    }
}