$default-color: $base-light;
$default-hover: darken($base-light, 10%);

$primary-color: $brand-primary;
$primary-hover: darken($brand-primary, 10%);


$secondary-color: $brand-tertiary;
$secondary-hover: darken($brand-tertiary, 10%);


$button-border: none;
$button-border-radius: 4px;

.btn,
input[type="button"],
input[type="reset"],
input[type="submit"],
button{

	border: $button-border;
	border-radius: $button-border-radius;
	display:inline;
	width:auto;
	box-shadow:none;
	
	min-width: 7em;
	padding: 0.4em 1.5em 0.45em;

	display: inline-block;
	text-decoration: none;

	cursor: pointer;

	font-family: $font-primary;
	font-weight:700;
	text-align:center;
	text-transform: uppercase;
	font-size: 1.1em;

	background-color: $primary-color;
	color :white;

	transition: background-color $trans-out ease-out;

	//remove the pesky ios stuff, thanks steve
	-webkit-appearance: none;




	&:link,
	&:visited{
		background-color: $primary-color;
		color :white;
	}

	&:hover,
	&:active{
		background-color: $primary-hover;
		color :white;
		text-decoration: none;
		transition: background-color $trans-in ease-out;

	}

	&:disabled,
	&:disabled:hover{
		background-color: darken($primary-color, 16%);
		cursor: not-allowed;
		-webkit-appearance: none;
		
		/* THANKS STEVE!!!!! */
		-webkit-text-fill-color:#fff; /* Override iOS / Android font color change */
		-webkit-opacity:1; /* Override iOS opacity change affecting text & background color */
		color:white; /* Override IE font color change */

	}


}


.btn-link,
button.btn-link{
	background:none;
	border:none;
	box-shadow:none;
	padding:0;
	text-decoration:none;
	display:inline;
	min-width:0;
	font-weight:normal;
	text-transform: none;
	color: darken($link-normal, 15%);
	text-align: left;
	cursor: pointer;
	
	transition: color $trans-in ease-out;
	
		//handle the icons inside of links
	.icon{
		fill:  $link-normal;
		transition:fill $trans-out ease-out;
	}



	&:before,
	&:after{
		display:none;
	}

	&:hover,
	&:active,
	&:focus{
		text-decoration: underline;
		background:none;

		.icon{
			fill:  $link-hover;
			transition: fill $trans-in ease-out;
		}

	}
}

.gm-control-active,
.gm-svpc{
	min-width:0;
}