.flood-monitoring{
	text-align: center;
	.icon{
		position: relative;
		top:0.15em;
		fill:white;
	}

	a:hover,
	a:active{
		.icon{
			fill: white;
		}
	}
}