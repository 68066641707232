

.map-container{
	
	$slideout-size: 30px;
	$map-height: 517px;

	background-color:white;


	> .map-panel > div{
		position: relative;
		height: $map-height;
	}

	

	@media screen and (min-width: $slideout-hoz){

		position: relative;
		height: $map-height;

		.slideout-panel{
			height:100%;
		}

		.map-panel{
			position:absolute;
			left: $slideout-size;
			width: calc(100% - #{$slideout-size});
			height:100%;
			top:0;
		}

	
		
	}


// 	.map-panel{
// 		width: calc(100% - 400px);
// 		float:left;
// 	}
}

.map-content{
	@include clear;


	@include container-fluid;	

	max-width:400px;

	margin-left:auto;
	margin-right:auto;

	padding-top:1.5em;
	padding-bottom: 1.5em;



	// @media screen and (min-width: 820px) and (max-width: 1239px){
	// 	max-width:840px;
	// 	@include make_seciton_columns(6, ".card-wrap", ".map-gauge-wrap", false );
	// }


	@media screen and (min-width: 768px) and (max-width: 1239px){
		max-width:768px;
		@include make_seciton_columns(6, ".card-wrap", ".map-gauge-wrap", false );
	}


	@media screen and (min-width: 1240px ) and (max-width: 1679px){
		max-width:1240px;
		@include make_seciton_columns(4, ".card-wrap", ".map-gauge-wrap", false );
	}

	@media screen and (min-width: 1680px ){
		max-width: 1680px;
		@include make_seciton_columns(3, ".card-wrap", ".map-gauge-wrap", false );
	}


}

.map-wrap{
	background-color:#f9f9f9;

	.map-tables{
		max-width: 1680px;
		padding-bottom:2em;
	}
}

.non-responsive-gauges table,
.tabular-stream-gauges table,
.tabular-rain-gauges table{
	font-size:0.8em;

	.missed-measurement{
		color:red;
	}

	a:link,
	a:visited{
		color: $base-darkest;
		text-decoration: none;
	}
	a:hover,
	a:active,
	a:focus{
		text-decoration: underline;
	}
}




