.date-time-selector-container {
    padding-top: 1.5em;
    //padding-bottom: 1em;

    .DayPicker{
        font-size:1em;
    }

    .DayPicker-wrapper {

        .DayPicker-Weekday {
            border: none;

            abbr {
                text-decoration: none;
                border: none;
            }
        }

        .DayPicker-Caption {
            padding-bottom: 1.3em;
        }

        .DayPicker-Months{
            .DayPicker-Month:first-child{
                margin-left:0;
            }
        }

        .DayPicker-Month {
            margin-top:0;
            margin-bottom: 3rem;
            &:nth-child(2){
                @media screen and ( min-width:768px ) {
                    padding-right: 3em;
                }
            }

            .DayPicker-Caption {
                padding-bottom:0;
                select {
                    display: inline-block;
                    padding: 3px 25px;
                    border-radius: 0;
                    margin-bottom:0;

                    &:first-child {
                        margin-left: -10px;
                    }
                }
            }
        }


        .DayPicker-NavButton{
            margin-top: 0em;
            top:0.2em;
        }

        .DayPicker-NavButton--prev{
            right:auto;
            left:0em;
            margin-right:0;
        }

        .DayPicker-NavButton--next{
            right:1em;
        }


        .DayPicker-Weekday {
            //background-color: #d9f4ff;
            color: #505858;    
            //width: 43px;
            font-size:1em;
        }

        .DayPicker-Day {
            background-color: #d9f4ff;
            color: #000;    
            width: 43px;
            border-radius:0;
            border: 1px solid #eaecec;
        }

        .DayPicker-Day--disabled {
            background-color: #eaecec;
            color: #000;    
        }

        .DayPicker-Day--selected {
            background-color: #065373;
            color: #fff;    
        }

        .DayPicker-Day--dateTo,
        .DayPicker-Day--dateFrom {
            background-color: darken(#065373, 10%);
            //background-color: #000;
        }

        .DayPicker-Day--highlighted {
            background-color: #ffd87b;
            color: #000; 
        }

        .DayPicker-Day--unhighlighted {
            background-color: #d9f4ff;
            color: #000;    
            width: 43px; 
        }

        .DayPicker-Day--outside { 
            opacity: 0.4;
        }
    }

    .reset-date-time-btn {
        padding: 20px 25px;
        color: #fff;
        text-decoration: none;
        background-color: $base-color;

    }

    .validation-message {
        display: block;
        margin: 0.7em 0 2em 0;
        color: $validation-color;
    }
}


.date-time-selector-container.invalid {
    border: 2px solid $validation-color;
}

.DayPicker-Weekdays {
    margin-top: 50px;
}

.DayPicker.invalid {
    border: 2px solid $validation-color;
}

.date-time-selector-container .DayPicker-wrapper .DayPicker-Caption{
    text-align: center;
}