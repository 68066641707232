input,
textarea,
select{
	padding:0.5em;
	border-radius: 0.25em;
	border-color: $base-color;
	display:block;
	border-width:1px;
	width:100%;
	margin-bottom:1.6em;
	box-shadow: inset 1px 5px 9px -7px rgba(0,0,0,0.4);
	max-width:500px;
	&:disabled{
		cursor: not-allowed;
	}


}

select{
	width:auto;
}

input[type="radio"],
input[type="checkbox"],
input[type="file"],
input[type="button"],
input[type="range"]{
	box-shadow: none;
}


input[type="radio"],
input[type="checkbox"]{
	display:inline-block;
	width:auto;
	margin-right:0.5em;
	box-shadow: none;
}

/*textarea:disabled, 
input:not([type]):disabled, 
input[type="color" i]:disabled, 
input[type="date" i]:disabled, 
input[type="datetime" i]:disabled, 
input[type="datetime-local" i]:disabled, 
input[type="email" i]:disabled, 
input[type="month" i]:disabled, 
input[type="password" i]:disabled, 
input[type="number" i]:disabled, 
input[type="search" i]:disabled, 
input[type="tel" i]:disabled, 
input[type="text" i]:disabled, 
input[type="time" i]:disabled, 
input[type="url" i]:disabled, 
input[type="week" i]:disabled,
select:disabled{
    background-color: rgb(235, 235, 228);

}*/

input[type="search"]{

     -webkit-appearance: none;
     border-radius: 0;

}

.checkbox-label,
.radio-label,
input[type="radio"],
input[type="checkbox"]{
	label,
	+ label{
		font-weight: normal;
		font-family: $font-primary;
		font-size:1em;
		color: black;
	}
}

input[type="color"]{
	padding:0;
	display:inline;
	width: 23px;
    height: 23px;
    display:block;
}

/*input[type="color" i][list] {
    width: 88px;
    height: 23px;
}*/

input[type="range"] + output{
	margin-top: -1em;
    display: block;
    margin-bottom: 1.6em;
}


select{
	font-size:0.9em;
	padding: 8px 12px;
	width:auto;
	


	background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTAwcHgiIGhlaWdodD0iMTAwcHgiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMDAgMTAwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBmaWxsPSIjMDAwMDAwIiBkPSJNNTAuMTExLDcyLjY1OWMtMS4wNjEsMC0yLjA3OC0wLjQyMi0yLjgyOS0xLjE3MkwxNy4yNzUsNDEuNDc0Yy0xLjU2Mi0xLjU2Mi0xLjU2Mi00LjA5NSwwLjAwMS01LjY1NyAgYzEuNTYzLTEuNTYyLDQuMDk2LTEuNTYyLDUuNjU3LDBsMjcuMTc4LDI3LjE4NWwyNy4xNzgtMjcuMTg1YzEuNTYyLTEuNTYyLDQuMDk1LTEuNTYyLDUuNjU3LDAgIGMxLjU2MiwxLjU2MiwxLjU2Miw0LjA5NCwwLjAwMSw1LjY1N0w1Mi45NCw3MS40ODdDNTIuMTg5LDcyLjIzNyw1MS4xNzIsNzIuNjU5LDUwLjExMSw3Mi42NTl6Ii8+PC9zdmc+");
	background-position: right 4px center;
	background-repeat: no-repeat;

	background-size: 18px;
    padding-right: 1.9em;
	
	-webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;



}

select[multiple]{
	background-image:none;
}


::-webkit-input-placeholder {
   color:  rgba(90, 104, 115, .5);
}

:-moz-placeholder { /* Firefox 18- */
   color:  rgba(90, 104, 115, .5);  
}

::-moz-placeholder {  /* Firefox 19+ */
   color:  rgba(90, 104, 115, .5);  
}

:-ms-input-placeholder {  
   color:  rgba(90, 104, 115, .5);  
}

label{
	font-weight: bold;
	color:$base-darkest;
	font-size:1.1em;
	margin-bottom:0.6em;
	display:inline-block;
}

.label-sub{
	font-family: $font-primary;
	font-size:0.9em;
}


legend{
	font-weight: bold;
	font-size:1.2em;
	margin-bottom:1em;
}

.required{
	color: $validation-color;
}


input[type="radio"] {
	border: 1px solid #bbb;
	background: #fff;
	color: #555;
	clear: none;
	cursor: pointer;
	display: inline-block;
	line-height: 0;
	height: 16px;
	margin: -4px 4px 0 0;
	//outline: 0;
	padding: 0 !important;
	text-align: center;
	vertical-align: middle;
	width: 16px;
	min-width: 16px;
	-webkit-appearance: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  
  	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-right: 4px;
	line-height: 10px;
  
}

input[type="radio"]:checked + label:before {
	color: #888;
}

input[type="radio"]:disabled,
input[type="radio"]:disabled:checked:before {
	opacity: 0.7;
}

input[type="radio"]:checked:before {
	float: left;
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	font: normal 21px/1;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  
  	content: '\2022';
	text-indent: -9999px;
	-webkit-border-radius: 50px;
	border-radius: 50px;
	font-size: 24px;
	width: 6px;
	height: 6px;
	margin: 4px;
	line-height: 16px;
	background-color: #1e8cbe;
}



input[type="checkbox"] {
	border: 1px solid #bbb;
	background: #fff;
	color: #555;
	clear: none;
	cursor: pointer;
	display: inline-block;
	line-height: 0;
	height: 24px;
	margin: -4px 4px 0 0;
	//outline: 0;
	padding: 0 !important;
	text-align: center;
	vertical-align: middle;
	width: 24px;
	min-width: 24px;
	-webkit-appearance: none;

	box-sizing: border-box;
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  

	border-radius: 0;
	margin-right: 13px;
	line-height: 16px;

	&:focus{
		border-color:$brand-tertiary;
	}
  
}

input[type="checkbox"]:checked + label:before {
	color: #888;
}

input[type="checkbox"]:disabled,
input[type="checkbox"]:disabled:checked:before {
	opacity: 0.7;
}

input[type="checkbox"]:checked:before {
	float: left;
	display: inline-block;
	vertical-align: middle;
	font: normal 21px/1;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  	border-radius: 0;
	font-size: 16px;
	width: 24px;
	height: 24px;
	margin: 0;
	line-height: 16px;

	content: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMmJhYmUyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgOTUuNDUzIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOTUuNDUzIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PHBvbHlnb24gcG9pbnRzPSI5NC41ODcsMjcuNDggMzEuNjU3LDg2LjE1IDMxLjIxNyw4NS42OCAxOC44NDcsNzIuNDEgMC41NjYsNTIuODEgMTQuMTA2LDQwLjE5IDMyLjM4Nyw1OS43OSA4MS43NzYsMTMuNzQgICAiLz48L2c+PC9nPjwvc3ZnPg==");
		
	top: -4px;
    left: 1px;
    position: relative;

}


.styled-checkbox-label{
	font-size:1em;
	font-weight: normal;
}

.form-field{
	
	margin-bottom:1.6em;

	input,
	textarea{
		margin-bottom:0;
	}

	.validation-message{
		display:none;
		color:$validation-color;
		margin-top:0.7em;
	}
	&.form-field-validation-error{   
		.validation-message{
			display:block;
		}
		input,
		textarea{
			border-color:$validation-color;
		}
		label,
		span,
		fieldset{
			color: $validation-color;
		}
	}

}
.form-field-required{
	color: $validation-color;
}

.form-validation-summary{
	color: $validation-color;
	margin-bottom:1em;
}