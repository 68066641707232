.dam-calculator-container {
    margin: 10px;
    margin-top: 30px;

    @media screen and ( min-width: 768px ) {
        margin: 1em;    
    }

    .dam-calculator {
        margin-bottom: 2em;

        .dam-calculator-title {
            display: block;
            margin-bottom: 1.8em;
            font-weight: bolder;
            font-size: 1.2em;
        }
    
        label {
            font-weight: normal;
            font-size: 1em;
            margin-right: 1em;
        }
    
        .water-elevation-input {
            display: inline-block;
            margin-bottom: 0;
            margin-top: 1em;
        }
    
        .water-elevation-input.invalid, .desired-ll-gate-opening-input.invalid, .desired-radial-gate-opening-input.invalid {
            outline-color: $validation-color;
            border-color: $validation-color;
        }
    
        .calculate-water-elevation-btn,.calculate-expected-outflow-btn {
            display: inline-block;
        }
    
        .calculate-expected-outflow-btn.disabled, .calculate-water-elevation-btn.disabled {
            opacity: 0.5;
        }
    
        .rate-of-change-box {
            display: inline-block;
            border: 1px solid #000;
            padding: 1.5em;
        }
    
        .return-data-label {
            display: block;
            font-style: italic;
    
            strong {
                font-style: normal;
            }

            .reached-threshold-warning {
                color: #fdb913;
            }
        }
    
        .return-data-label.gap-top {
            margin-top: 1em;
        }
        
    
        .return-data-label.inline {
            display: inline-block;
            margin:1em 0 1em 0;
    
            @media screen and ( min-width: 768px ) {
                margin:2em 3em 2em 0;
            }
        }
    
        .gate-opening-label {
            margin-top: 1.5em;
    
            input {
                margin-top: 1em;
            }
        }
    
        .gate-opening-col {
            display: block;
            //float: left;
            margin-right: 3em;
            margin-left: 15px;
    
            @media screen and ( min-width: 1024px ) { 
                float: left;
            }
    
            span {
                margin:1em 0;
                @media screen and ( min-width: 1024px ) { 
                    margin:1em 3em 1em 0;
                }
            }
        }
    
        input {
            border-radius: 0;
            margin-bottom: 0;
        }
    
        button {
            border-radius: 0;
            color: #000;
        }
    
        .error-msg {
            //display: inline-block;
            max-width: 300px;
        }
    
        .error-msg.elevation-input {
            margin-bottom: 1em;
            max-width: 100%;
        }
    }
}