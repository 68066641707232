

// Baseline styles

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 24px;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 10px;
        vertical-align: top;
        border-top: 1px solid $base-color;

      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    background-color: $base-dark;
    color:$base-lightest;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $base-color;
  }

  // Nesting
  .table {
    background-color: $base-light;
  }
}


.table-lined{

    margin-bottom:0.8em;

    th,td{
      border-top:1px solid grey;
      padding-left:1em;
      padding-right:1em;
      padding-top: 0.8em;
        padding-bottom: 0.8em;

    }

    tr:last-child{
      th,td{
        border-bottom:1px solid grey;
      }
    }

    tbody tr:nth-child(even){
      background-color:$base-lightest;
    }

    tbody tr:nth-child(odd){
      background-color: $base-light;
    }
}

.col-center{
  text-align: center;
}

.responsive-table-wrap{
  overflow-x:auto;
  margin-bottom:1em;
  table{
    margin-bottom:0;
  }
}

.tabular-icon{
  font-size:1.2em;
}