//
// Alerts
// --------------------------------------------------
//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               15px !default;
$alert-border-radius:         4px;
$alert-link-font-weight:      bold !default;

$alert-success-bg:            #dff0d8 !default;
$alert-success-text:          #2d4821 !default;
$alert-success-border:        #d6e9c6 !default;

$alert-info-bg:               #d9edf7 !default;
$alert-info-text:             #00ace6 !default;
$alert-info-border:           #bce8f1 !default;

$alert-warning-bg:            #f9f1c6 !default;
$alert-warning-text:          #6c4a00 !default;
$alert-warning-border:        #faebcc !default;

$alert-danger-bg:             #f2dede !default;
$alert-danger-text:           #d2322d !default;
$alert-danger-border:         #ebccd1 !default;

// Alerts

@mixin alert-variant($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color;

  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($text-color, 10%);
  }
}



// Base styles
// -------------------------

.alert {
  padding: $alert-padding;
  margin-bottom: 24px;
  border: 1px solid transparent;
  border-radius: $alert-border-radius;

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing $headings-color
    color: inherit;
  }

  // Provide class for links that match alerts
  .alert-link {
    font-weight: $alert-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}


// Adds an icon to the alertbox and moves the content over
.alert.has-icon {

  padding-top:1em;
    padding-bottom:1em;

  .alert-icon {

    font-size: 2.5em;
    text-align: center;
    padding-bottom: 0.2em;
    
  }

  @media screen and (min-width: $bp-sm){

    padding-left: 5.5em;
    padding-top:2em;
    padding-bottom:2em;
    position: relative;
    .alert-icon{
      position: absolute;
      height: 1em;
        line-height: 1em;

      margin-top:auto;
      margin-bottom: auto;
      
      left: 0.65em;

      top:0;
      bottom:0;
    }

  }
}