.slideout-content{

    fieldset {
        margin-bottom: 40px;
        .station-list {
            margin: 0;
            padding: 0;

            li {
                clear: both;
                list-style: none;
                text-transform: capitalize;
                margin-right: 15px;

                &.no-selected-gauges{
                    text-transform: none;
                }
            }
        }

        .report-template-btn {
            display: block;
            text-decoration: none;
            color: $base-color;
            background-color: rgba(0,0,0,0);
            text-align: left;
            padding: 0;
            margin: 0;
            font-size: 1em;
            font-weight: normal;
            margin-bottom: 30px;
            text-transform: none;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
        }

        .report-template-btn.active {
            font-weight: bold;
        }

        .report-template-btn.disabled {
            opacity: 0.4;
            cursor: auto;

            &:hover {
                font-weight: normal;
            }

            &:active {
                opacity: 0.4;
                cursor: auto;
            }
        }

        .clear-all-btn {
            float: left;
            margin-right: 0;
            margin-left: 3em;
            text-align: left;
            margin-top: 10px;
            background-color: rgba(0,0,0,0);
            color: #000;
            padding: 0;
            padding-top: 10px;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .selected-gauges-container {
        list-style: none;
        margin-left: 0;
        margin-top: 0;
        padding-top: 15px;

        li {
            display: inline-block;
            &:last-of-type {
                float: left;
            }

            &:first-of-type {
                //margin-top: -8px;
            }
            
            .menu-gauge-selection {
                display: inline-block;
                height: 50px;
                margin-bottom: 0;
                background-color: lighten($base-light, 3%);
                border-radius: 10px;
                
                span {
                    display: block;
                    //margin-top: 
                    padding: 15px 23px 10px 17px;
                    font-size: 13px;
                    font-weight: bold;
                }
            }
            .remove-gauge-selection-button {
                display: inline-block;
                position: relative;
                top: 2px;
                min-width: 26px;
                width: 26px;
                height: 24px;
                margin-left: -15px;
                padding: 0;
                padding-top: 2px;
                text-align: center;
                background-color: #fff;
                border-radius: 5px;
                cursor: pointer;
                transition: all .2s ease-in-out;

                &:hover {
                    background-color: darken(#fff,10%);
                }

                @media screen and (min-width: 1200px){
                    top: 4px;
                }

                .icon-container {
                    pointer-events: none;
                    font-weight: bold;
                    font-size: 16px;
                    cursor: pointer;
                    .icon {
                        fill: #282c2d;
                    }
                }   
            }

            .remove-gauge-selection-button.hidden {
                display: none;
            }
        }
    }

    .selected-gauges-container.standard-report {
        margin: 0;
        padding: 0;
    }

    .add-gauge-btn {
        clear: both;
        display: block;
        float: left;
        margin-left: 0;
        margin-top: 0;
        padding: 0;
        min-width: 40px;
        width: 40px;
        height: 37px;
        font-size: 2em;
        background-color: #fff;
        border-radius: 5px;
        cursor: pointer;
        transition: $trans-out;
        margin-top:0.4em;
        color: #000;

        &:hover {
            background-color: darken(#fff,10%);
             transition: $trans-in;
        }

        span {
            display: block;
            margin-top: -16px;
        }

        .icon-container {
            pointer-events: none;
            font-weight: bold;
            text-align: center;
            padding-top: 4px;

            .icon {
                fill: #282c2d;
            }
        }
    }
}