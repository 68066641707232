.report-results {
    position: relative;
    @include clearfix;
    
}


.report-chart-result{
    margin-bottom: 7em;
    .card-missed-data-overlay {
        position: relative;
        height: 100%;
        width: 100%;
        text-align: center;
        top: -100%;
        left: 0;
        padding-top: 2em;
        padding-bottom: 3em;
        font-size: 3em;
        background-color: rgba(255, 255, 255, 0.9);
        display: none;
    }

    .card-missed-data-overlay.visible {
        display: block;
    }

    .chart-result {
        .card-missed-data-overlay {
            position: relative;
            height: 100%;
            width: 100%;
            text-align: center;
            top: -100%;
            left: 0;
            padding-top: 3em;
            font-size: 3em;
            background-color: rgba(255, 255, 255, 0.9);
            display: none;
        }

        .card-missed-data-overlay.visible {
            display: block;
        }
    }
}

.report-chart-result.temporal{
    margin-bottom: 1em;
}

.report-btn-container {
    clear: both;
    float: left;
    text-align: left;
    margin-top: 0;
    width: 100%;
    margin-right: 15px; 
    padding-bottom: 2em;
    padding-top: 2em;

    .report-btn {
        display: inline-block;
        background-color: $base-color;
        height: 65px;
        margin-right: 25px;
    }

    .report-btn.disabled {
        opacity: 0.65; 
        cursor: not-allowed;
    }

    .report-validation-msg {
        display: block;
        margin-top: 10px;
        color: $validation-color;
    }
}

.reports-container,
.report-results {
    position: relative;
    background-color: #fff; 

    @include clearfix;

    .reports-content-container {
        display: block;

        .reports-instructions {
            display: block;
            margin-top: 2em;
        }

        .time-picker-container {
            width: 100%;
            //max-width: 25em;
            margin-bottom: 20px;

            fieldset {
                display: block;
                float: left; 
                width: 100%;
                //margin-left: 10px;

                label{
                    width: 100%;
                }

                &:first-child{
                    margin-right: 8.5em;
                }

                span {
                    display: block;
                    font-size: 15px;
                    margin-bottom: 20px;
                }

                input {
                    display: block;
                    border-radius: 0;
                    width: 100%;
                    margin-bottom: 5px;
                    max-width: 100%;

                    @media screen and ( min-width:768px ) {
                        width: 60%;
                    }
    
                    @media screen and ( min-width:1024px ) {
                        
                    }


                    &:focus {
                        border-color: $brand-tertiary;
                    }
                }

                input.invalid {
                    border-color: $validation-color;
                }

                @media screen and ( min-width:768px ) {
                    //float: left;
                    //display: inline-block;
                    max-width: 200px;

                    &:first-child {
                        margin-right: 2em;
                    }
                }

                @media screen and ( min-width:1024px ) {
                    &:first-child {
                        margin-right: 1em;
                    }
                }
            } 

            .checkbox-toggle {
                //display: inline-block;
                float: left;
                width: 150px;
                
                input {
                    display: inline-block;
                    width: 20px;
                }

                fieldset {
                    width: 100%;
                    label {
                        font-size: 1em !important;
                    }
                }

                @media screen and ( min-width:768px ) {
                    display: inline-block;
                    margin-top: 2.8em;
                }

                @media screen and ( min-width:1000px ) {
                    float: left;
                    
                }
            }

            @media screen and ( min-width:768px ) {
                //margin-top: 30px;
                
            }
        }

        .date-time-feedback {
            clear: both;
            padding-top: 2em;
            padding-bottom:3em;
            font-size: 0.9em;
            span {
                font-weight: bold;
            }
        }

        .temporal-rainfall-report {
            table {
                font-size: 0.5em;

                thead {
                    th {
                        min-width: 60px;
                        padding: 0 10px;
                    }
                }

                tbody {
                    text-align: center;
                    td {
                        text-align: center;
                        vertical-align: middle;

                        &:first-of-type {
                            padding-right: 15px;
                            text-align: left;
                        }
                    }
                }
            }
        }

        .plot-stations-checkbox-toggle {
            margin-top: 2em;
            font-size: 1em;
        }
    }

    .reports-content-container.hidden {
        display: none;
    }

    .station-search-container {


        .search-input-title {
            display: block;
            padding-bottom: 1.3em;
        }
        
        span {
            display: block;
        }

        

        .search-filter-container {
            //width: 95%;
            max-width: 30em;
            margin-bottom: 1em;

            .search-filter-form {
                position: relative;
                .search-filter-input {
                    display: block;
                    float: left;
                    width: 100%;
                    border: none !important;
                    border: 2px solid $base-light !important;
                    //border-right: none !important;
                    border-radius: 0;
                    max-width: none;
                    margin-bottom: 0;
                    padding-left: .8em;
                    box-shadow: none !important; 

                    &:focus {
                        border-color: $base-light !important;;
                        outline-color: #fff !important;
                    }
                }

                .search-filter-input.active {
                    border-color: $brand-tertiary !important;
                    outline-color: #fff !important;
                }

                .search-filter-input.invalid {
                    border-color: $validation-color !important;
                    outline-color: #fff !important;
                }

                .search-list-toggle-btn {
                    position: absolute;
                    //float: right;
                    text-align: center;
                    width: 30%;
                    top: 0;
                    right: 0;
                    padding: 0;
                    height: 100%;
                    //border: 2px solid $base-light;
                    //border-left: none;
                    border-radius: 0;
                    background-color: #fff;
                    font-size: .8em;
                    font-weight: bold;
                    cursor: pointer;

                    span {
                        display: inline-block;
                        text-transform: none;
                        font-size: 14px;
                        color: $base-color;
                        margin-right: 5px;
                        margin-top: 5px;
                        padding-top: 13px;
                    }

                    &:hover {
                        background-color: darken(#fff, 30%);
                    }

                    &:focus {
                        //background-color: $brand-tertiary;
                        //border-color: $brand-tertiary !important;
                        //outline-color: #fff !important;
                    }
                    
                    .icon {
                        display: inline-block;
                        font-size: 13px;
                        fill: $base-color;
                        stroke: $base-color;
                        transition: all .1s ease-in-out;
                    }

                    @media screen and (min-width: 1200px) {
                        //height: 54px;
                    } 
                }

                .search-list-toggle-btn.open {
                    background-color: $brand-tertiary;
                    border-color: $brand-tertiary !important;
                    outline-color: #fff !important;
                    .icon {
                        transform: rotate(180deg);
                        transform-origin: 50% 50%;
                    }
                }

                .search-list-toggle-btn.closed {
                    background-color: #fff;
                    border-color: $base-light !important;
                    outline-color: #fff !important;
                    .icon {
                        transform: rotate(0deg);
                        transform-origin: 50% 50%;
                    }
                }

                .search-list-toggle-btn.invalid {
                    border-color: $validation-color !important;
                    outline-color: #fff !important;
                }
            }
    
            .station-filtered-list {
                clear: both;
                display: none;
                margin: 0;
                //max-height: 500px;
                //overflow-y: scroll;
                padding: 0;
                width: 100%;
                visibility: hidden;

                .station-item {
                    list-style: none;
                    //padding: .4em 0 .4em 1em;
                    margin: 0;
                    width: 100%;
                    border-color: $base-light !important;
                    border-bottom: 2px solid;
                    border-left: 2px solid;
                    border-right: 2px solid;
                    font-size: .8em;

                    .station-list-title {
                        padding: 15px 0 15px 10px;
                    }

                    .add-station-button {
                        float: right;
                        min-width: 25px;
                        padding: 0;
                        width: 25px;
                        height: 25px;
                        margin-right: 20px;
                        border-radius: 5px;
                        background-color: $base-dark;  
                        cursor: pointer;
                        transition: .2s ease-in-out;
                        
                        &:hover{ 
                            background-color: lighten( $base-dark, 30% );
                        } 

                        span {
                            display: block;
                            //margin-top: -6px;
                            color: #fff;
                            //font-size: 1.5em;
                            text-align: center;
                        }
                    }

                    .add-station-button.active {
                        //background-color: $validation-color;
                    }

                    .station-gauges-container {
                        display: none;
                        //width: 85%;
                        //margin-top: 1em;

                        .gauge-list-container {
                            width: 100%;
                            height: auto;
                            //background-color: $base-light;
                            padding: 0 0 0 10px;
                            margin: 0;

                            span {
                                display: inline-block;        
                                float: left;
                                padding-bottom: 5px;
                            }

                            .add-all-sensors-btn {
                                display: inline-block; 
                                //float: right;
                                margin-right: 0;
                                background-color: rgba(0,0,0,0);
                                color: #000;
                                padding: 0;
                                padding-top: 5px;
                                font-size: 14px;
                                cursor: pointer;

                            }

                            .sensor-list-container {
                                clear: both;
                                margin-top: 15px;
                                margin-bottom: 35px;


                                li {
                                    display: inline-block;
                                    margin-right: 20px;
                                    width: auto;
        
                                    .add-gauge-button {
                                        background-color: #d9f4ff;
                                        color: #000;
                                        border-radius: 8px;
                                        width: auto;
                                        font-size: 14px;
                                        height: 36px;
                                        //-webkit-box-shadow: 1px 0px 1px 1px rgba(168,166,168,1);
                                        //-moz-box-shadow: 1px 0px 1px 1px rgba(168,166,168,1);
                                        //box-shadow: 1px 0px 1px 1px rgba(168,166,168,1);
                                        cursor: pointer;
                                        //border: 1px solid darken($base-light,30%);
                                        //padding: 10px 5px;

                                        &:hover{ 
                                            //background-color: darken( $base-dark, 25% );
                                        }
                                        
        
                                        span {
                                            display: block;
                                            margin: 3px 15px;
                                        }   
                                    }
        
                                    .add-gauge-button.active {
                                        background-color: #ffd87b;
                                    }
                                }
                            }
                        }
                    }

                    .station-gauges-container.visible {
                        display: block;
                    }
                }

                .station-item.selected {
                    //background-color: $base-light;
                    .station-list-title {
                        position: relative;
                        background-color: $base-light;
                        //-webkit-box-shadow: 0px 3px 5px 1px rgba(214,207,214,1);
                        //-moz-box-shadow: 0px 3px 5px 1px rgba(214,207,214,1);
                        //box-shadow: 0px 3px 5px 1px rgba(214,207,214,1);
                    }

                    .station-list-title.standard-report{
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }
                }
            }
            
            .station-filtered-list.visible {
                visibility: visible;
                display: block;
                border: 2px solid $brand-tertiary;
            }

            .station-filtered-list.invalid {
                visibility: visible;
                display: block;
                border: 2px solid $validation-color;
            }

            @media screen and (min-width: 768px) {
                
            }
        }
    }

}

.error-msg {
    display: block;
    color: $validation-color;
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    margin-top: 1em;
    margin-bottom: 1em;
}

.report-result-buttons{
    text-align: center;

    a {
        background-color: #fff !important;
    }
}

.save-chart-btn {
    text-align: center;
    display: block;
    margin-bottom: 2em;
    margin-top: 1em;
    
    a {
        background-color: #fff !important;
        text-transform: capitalize;
    }
}


// PRECIP Report
.precip-report-map{
    width:100%;
    height:700px;
    margin-bottom:4em;
}

.precip-report .summariezed-data{
    max-width:600px;
    margin-left:auto;
    margin-right:auto;
}

.summariezed-data table{
    margin-top: 3em; 
    font-size: .7em;
}

.reports-content-container .trca-map-gauge:hover{
    z-index: 20;
}

//DAM Report
.no-summary-results {
    display: block;
    padding: 15px 0;
    width: 100%;
    margin-top: -1em;
    margin: 0 auto; 
    background-color: $base-light;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: -18px;
    text-align: center;
}

// third party pill
.third-party-tag{
    text-transform: uppercase;
    display:inline-block;
    padding:0.2em 0.6em;
    background-color:#7363B7;
    color:white;
}

.reports-container .station-search-container .third-party-tag,
.report-results .station-search-container .third-party-tag{
    display:inline-block;
    margin-right:0.9em;
    margin-top: -0.2em;
    margin-bottom: -0.2em;
}

.selected-gauge-list-heading .third-party-tag{
    display:table;
    font-size:0.65em;
    margin-bottom:0.2em;
}