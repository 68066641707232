.battery-data-table {}

.battery-legend-container {
    text-align: center;
    .legend-item {
        display: inline-block;
        margin-right: 20px;
    }
}

.battery-marker {
    display: inline-block;
    width: 60px;
    height: 20px;
    text-align: left;
    margin-right: 5px;
    vertical-align: middle;
}

.battery-marker.low {
    background-color: #CC4600;
}

.battery-marker.critical {
    background-color: #FFDB1A;
}

.battery-marker.good {
    background-color: #86CC00;
}

.report-result-buttons{
    text-align: center;
    margin-top: 3em;

    a {
        background-color: #fff !important;
    }
}