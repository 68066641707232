
.header{

	max-width:none;


	a:link,
	a:visited{
		color: white;
		text-decoration: none;
	}

	a:hover,
	a:active{
		color: white;
		text-decoration: underline;
	}
	
	.logo-area,
	.menu-btn{
		height:5em;
	}

	.header-top-wrap{
		background-color:#474D4F;
	}

	.logo-area{
		
		@include container-padding;

	}

	
	.header-bottom{
		@include clear;
		background-color: #5B5F61;
		font-size:0.8em;
		padding-top: 0.75em;
		padding-bottom:0.75em;

		text-align: center;

		> div {
			//padding-top:0.5em;
			clear:both;
		}


	}


	.logo{

		img{
			margin-top: 1.24em;
			width:130px;
			height:auto;

			@media screen and (min-width:460px){
				margin-top:0.94em;
				width:160px;
			}

			@media screen and (min-width:$bp-xl){
				margin-top: 3.3em;
    			max-width: 310px;
    			height: auto;
    			position: relative;
    			left:-0.3em;
			}

			@media screen and (min-width:1800px){
				margin-top: 1.2em;
   				/*width: 414px;*/
   				max-width: 414px;
    			height: auto;

			}
		}
	}


	.menu-btn{
	    display: block;
	    width: 100%;
	    max-width: 6em;
	    line-height: 5.3em;
	    padding-top:0.2em;
	    text-align: center;
	    float: right;
		background-color: $base-darkest;
		position: relative;
		margin-top:-5em;
		z-index: 10;
		transition: background-color $trans-out ease-out, color $trans-out ease-out;
		padding: 0;
    	text-transform: none;
    	border-radius: 0;
    	    font-size: 1em;
    	


		&:hover {
			background-color:$base-dark;
			cursor:pointer;
			text-decoration:none;
			transition: background-color $trans-in ease-out, color $trans-in ease-out;
		}

		&:focus,
		&.focus{
			outline: dotted 1px #00ace6;
			cursor:pointer;
			text-decoration:none;
		}


	}
	.more-menu-open,
	.more-menu-close{
		color:white;
		font-size: 0.9em;
		.icon{
			fill:white;
			position: relative;
			left:-0.1em;
			top:0.1em;
		}
	}
	
	.menu-open{

		.more-menu-open{
			display:none;
		}
		.more-menu-close{
			display:inline;
		}

		&.main-navigation{
			display:block;
		}
	}

	.menu-hidden{
		.more-menu-open{
			display:inline;
		}
		.more-menu-close{
			display:none;
		}
		&.main-navigation{
			display:none;
		}
	}

	.build-mode-button{

			background-color:$base-color;
		
	}

}


// main nav
@media screen and (min-width: $bp-sm) and (max-width: $menu-go-big - 1px ){

	.header{

		.main-navigation{
			position: absolute;
			height:100%;
			left:100%;
			top: 0;
			width: 300px;
			z-index: 100;
			background-color:$base-darkest;
			display:block;

		}
		.menu-hidden{
			&.main-navigation{
				display:block;
			}
		}

	}


	.slideout-wrap{ 

		transition: transform .25s linear;
		transform: translate3d(0, 0, 0);

		&.slideout-open{			
			position: relative;
			transform: translate3d(-300px, 0, 0);
			transition: transform .25s ease-out;
		}
	}

	#root{
		overflow: hidden;
	}

}

@media screen and (min-width:  $menu-go-big ){

	.header{

		$logo-size: 190px;

		.header-top-wrap{
			@include container-padding;
			@include clear;
			
			margin-left:auto;
			margin-right:auto;
			padding-right:0 !important;
		}

		.logo-area{
			padding-left:0;
			padding-right:0;
			float:left;
			width:$logo-size;
		}

		.logo img{
			margin-top: 1.6em;
			max-width: $logo-size;
			width: 100%;
			height: auto;
		}

		.right-container{
			position: relative;
			padding-right: 120px;
			float:right;
			width: calc( 100% - 245px  );
			background-color: $base-darkest;
		}


		.build-mode-button{
			position:absolute;
			right:0;
			top:0;
			width:120px;

		}


		.logo-area,
		.right-container,
		.menu-item-wrap{
			height: 126px;
		}

		.menu-buttons{
			padding:0;
		}

		.menu-btn{
			display:none !important;
		}

		.main-navigation{
			display:block !important;
		}


		.main-navigation{
			.dashboard-build-menu,
			.dashboard-multi-menu{
				display:none;
			}
		}
	} 

}



@media screen and (min-width:$bp-lg){
	.header{

		$logo-size: 225px;

		$header-height: 136px;
				

		.logo img{		
			//margin-top: 1em;
			max-width: $logo-size;
		}
		.logo-area{
			width:$logo-size;
		}

		.logo-area,
		.menu-btn,
		.right-container,
		.menu-item-wrap{
			height: $header-height;
		}


		.right-container{
			width: calc( 100% - #{$slideout-distance + 4px}  );
		}

		.main-navigation{
			font-size:0.9em;
		}

		.menu-buttons .menu-item-wrap{
			max-width:180px;
			.menu-item{
				padding:38px 25px 0 25px;
			}
		}

		.build-mode-button .menu-item-wrap{

			.menu-item{
			    padding-left: 12px;
    			padding-right: 12px;
			}
		}

		.right-container{
			padding-right: 180px;
		}

		.build-mode-button{
			width:140px;
		}


	}
}





@media screen and (min-width: $slideout-goes-bigger){
	.header{

		.logo-area,
		.menu-btn,
		.right-container,
		.menu-item-wrap{
			height: 155px;
		}

		.right-container{
			float:right;
			width: calc( 100% - #{$slideout-disance-bigger + 3px} );
		}

		$logo-size: 300px;

		.logo img{		
			margin-top: 1.3em;
			max-width: $logo-size;
		}

		.logo-area{
			width:  $logo-size;
		}

		.menu-buttons .menu-item-wrap{
			.menu-item{
				padding-top:45px;
				font-size:1.1em;
			}
		}

		.header-top-wrap{
			.logo-area{

				margin-left:$slideout-left-padding-bigger - $wrap-med;
			}
		}


	}
}



@media screen and (min-width: 1660px){
		.header{


		.build-mode-button{
			width:180px;
		}

	}

}