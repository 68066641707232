.dashboard-container {
    min-height: 500px;
    background-color: #fff;

    .deleted-dashboard-confirmation-message {
        text-align: center;
        margin-top: 150px;
        min-height: 300px;
        font-size: 2em;
    }


    .dashboard-grid-wrap{    
        padding-top:1em;
        padding-bottom:4em;
        padding-left:1em;
        padding-right:1em;

        .page-title{
            margin-top:1em;
            margin-bottom:0.3em;
        }
    }


    .dashboard-grid-item{
        position: relative;


        .dashboard-grid-item-loading-wheel{
            width:20px;
            height:20px;
            position: absolute;
            top:5px;
            right:5px;

        }

        .card{
            margin-top:0;
            margin-bottom:0;
        }

        .edit-widget-overlay {
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0);
            cursor:move;
            

            &:hover {
                .edit-widget-side-menu {
                    transform: translate3d(-80px, 0, 0);
                    transition: transform .2s ease-out;
                }
            }

            .edit-widget-side-menu {
                position: inherit;
                width: 80px;
                height: 100%;
                left: 100%;
                z-index: 7;
                //transform: translate3d(0px, 0, 0);
                background-color: #474D4F;
                transition: transform .2s linear;

                .edit-wiget-menu-btn {
                    margin: 0 auto;
                    margin-top: .7em;
                    font-size: 1.5em;
                    color: #fff;
                    transition: all .2s ease-in;

                    button {
                        min-width: 0;
                        width: 100%;                        
                        padding: 0;
                        border-radius: 0;
                        //padding: 10px 0;
                        background-color: rgba(0,0,0,0);
                    }

                    .icon {
                        fill: #fff;
                        stroke: #fff;
                    }

                    &:hover {
                        .icon {
                            fill: #e8eeef;
                            stroke: #e8eeef;
                        }
                    }
                }
            }
        }

        .edit-widget-overlay.link-widget {
            .edit-widget-side-menu {
                width: 160px;

                .edit-wiget-menu-btn {
                    float: left;
                    margin-top: 17px;
                    margin-left: 30px;
                    
                }
            }

            &:hover {
                .edit-widget-side-menu {
                    transform: translate3d(-160px, 0, 0);
                    transition: transform .2s ease-out;
                }
            }
        }

        .edit-widget-overlay.weather-forecast-widget {
            position: absolute !important;
            top: 0;
            height: 150px;
        }
    }

    // dashboard grid item sizes
    $height-standard: 358px;

    .dashboard-item-wrap{
        position: relative;
    }

    .dashboard-grid-item{
        height:$height-standard;
        background-color: grey;
        position: absolute;
        width:100%;
        height:100%;

        > div{
            position: absolute;
            width:100%;
            height:100%;

        }
        .card{

            height:100%;
        }
    }

    .dashboard-feedback-container {
        margin-left: 2em;
        margin-right: 2em;
    }

}



.dashboard-grid-item-link{

    a:link,
    a:visited{
        padding:1.1em 2em;
        background-color: $base-darkest;
        color: $base-lightest;
        display:block;
        text-decoration: none;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    }

    a:hover,
    a:active,
    a:focus{
        text-decoration: underline;
    }
}

.dashboard-grid-item-weather-alert{

    font-size:0.8em;

    .weather-alert{
        background-color: $base-darkest;
        color:white;
        padding:1.5em 2em;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
        overflow-y: auto;

        .weather-alert-heading{
            margin-top:0;
            font-size:1.1em;
            margin-bottom:0.5em;
        }

        .weather-alert-sub-heading{
            font-size:1.1em;
            margin-bottom:0.5em;
            margin-top:0.5em;
        }

        .weather-alert-date{

        }

        p{

        }
        
        a:link,
        a:visited{
            color:white;
            text-decoration: underline;
        }

        a:hover,
        a:active,
        a:focus{
            color:darken(white, 10%);
        }

        .weather-alert-item {
            
            max-height: 260px;
        }
    }
}

.dashboard-grid-item-weather-forecast{

    font-size:0.8em;

     > div{
         overflow-y: auto;
    }
    .weather-forecast{
         background-color: $base-darkest;
        color:white;
        padding:1.5em 2em;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);


        .weather-forecast-heading{
            margin-top:0;
            font-size:1.1em;
            margin-bottom:0.5em;
        }

        .weather-forecast-day{
            margin-bottom:3em;
        }

        .weather-forecast-day-heading{
            font-size:1.1em;
            margin-bottom:0.5em;
            margin-top:0.5em;
        }
        
        a:link,
        a:visited{
            color:white;
            text-decoration: underline;
        }

        a:hover,
        a:active,
        a:focus{
            color:darken(white, 10%);
        }
    }
}

.dashboard-grid-item-dam-opening{

    .dam-opening-heading{
        margin-bottom:1em;
    }

    .dam-gate-status{
        max-width:none;
    }
}

.react-grid-layout,
.dashboard-grid-wrap .page-title{
    max-width: 2100px;
    margin-left:auto;
    margin-right:auto;
}