
.camera-image-camera-view{
	position: relative;
	padding-bottom:56.4%;
	overflow: hidden;
	background-color:grey;
	margin-bottom:12px;

	.icon{
		width:30px;
		display:block;
		width:100px;
		height:15px;
		position:absolute;
		bottom:10px;
		right:10px;
	}

	&.card-image-loading{

	}

	.camera-image-placeholder{
		position:absolute;
		left:0;
		right:0;
		width:100%;
		height:100%;
		background-color:grey;
	}

	.camera-image-error{
		display: flex;
		  align-items: center;
		  justify-content: center;
		.broken-svg{
			display:block;
			width:75px;
			height:75px;
			fill:#d2d2d2;
		}
	}

	.camera-image-img{
		position:absolute;
		width:100%;
		height:100%;
		object-fit: cover;
		top:0;
		left:0;
	}
}

.camera-view-wrap .card-gauge{
	min-height:0;

	.camera-image-img{
		cursor:pointer;
	}
}

.modal-camera{

	.modal-title{
		padding-right: 1.5em;
	}

	.modal-camera-controls{
		margin-top:1.2em;
	}

	.modal-camera-title-only{
		text-align: center;

	}

	.modal-camera-controls{
		display:flex;
		justify-content: center;
	}

	.prev-image,
	.next-image{

		min-width:0;
		padding:0;
		background-color:transparent;
		position:relative;
		top:0.1em;

		svg{
			fill: black;
		}

	}

	.prev-image{
		margin-right:1em;
	}

	.next-image{
		margin-left:1em;
	}

}

.map-camera-icon{
	position:relative;
	width:50px;
	height:30px;
	svg{
		fill: $brand-secondary;
		stroke:none;
		display: block;
		width:100%;
		height:100%;
	}
	.count{
		display: block;
		font-size:20px;
		font-weight: bold;
		position:absolute;
		//background-color:red;
		color:white;
		width:75%;
		height:90%;
		text-align: center;
		vertical-align: center;
		top:8%;
	}
}

.map-cluster-icon{
	position:relative;
	width:40px;
	height:40px;
	svg{
		//fill: $brand-secondary;
		//stroke:none;
		display: block;
		width:100%;
		height:100%;
	}
	.count{
		display: block;
		font-size:20px;
		font-weight: bold;
		position:absolute;
		//background-color:red;
		color:black;
		width:100%;
		height:100%;
		text-align: center;
		vertical-align: center;
		top:20%;
	}
}

.camera-wrap{
	padding-bottom:6em;
}

