.modal-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    background-color: rgba(0,0,0,0.5);
    z-index: 101;

    @media screen and ( min-width:768px ) {
        max-height: calc(100vh - 90px);
    }

    @media screen and ( min-width:1024px ) {
        left: 0;
        max-height: 100vh;
    }

    @media screen and ( min-width:1200px ) {
        //top: 173px;    
    }

    .modal-background-btn {
        position: inherit;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
        //z-index: 12;
    }
    
    .modal-content-container {
        position: absolute;
        left: 0; 
        right: 0; 
        overflow: auto;
        margin-left: auto; 
        margin-right: auto;
        width: 100%;
        height: 100%;
        max-width: 675px;
        padding: 15px;
        border: solid 1px $base-dark;
        background-color: #fff;

        @media screen and ( min-width:768px ) {
            height: auto;
            margin: 0 auto;
            max-width: 650px;
            min-height: 300px;
            padding-bottom: 30px; 
            overflow-y: hidden;
        
        }

        @media screen and ( min-width:813px ) {
            padding: 30px;
            padding-top: 15px;
            //margin-top: 60px;
        }

        @media screen and ( min-width:1024px ) {
            margin: auto;
            max-width: 750px;
            margin-top: 60px;
        }

        @media screen and ( min-width:1200px ) {
            max-width: 900px;
        }

        .station-search-container {
            //margin-bottom: 3em; 
            padding-top: 30px;
            width: 100%;
            min-height: 425px;
            //background-color: #fff;
            ul {
                li {
                    border-color: #fff !important;
                }
            }

            .search-filter-container {
                width: 100%;
            }
        }
    }

    .modal-content-container.fetch-error-msg {
        color: $validation-color;
        
        button svg {
            display: block;
            font-size: 1em;
            fill: #000;
        }

        svg {
            display: block;
            float: left;
            font-size: 4em;
            fill: $validation-color;
            stroke: #fff;
        }

        p {
            display: block;
            float: left;
            margin: 5px 0 0 20px;
        }
    }

    .modal-content-container.dam-calculator {
        //max-height: 700px;
        max-width: 1090px !important;
        background-color: $base-light;
    }

    .closeModalBtn {
        position: absolute;
        top: 6px;
        right: 0;
        width: 4em;
        height: 4em;
        min-width: 2em;
        padding: 0;
        background-color: rgba(0,0,0,0);

        @media screen and ( min-width:768px ) {
            right: 20px;
        }

        .icon {
            fill: #000;
            stroke: #000;
            font-size: 1.4em;
            transition: all .2s ease-in-out;

            &:hover {
                fill: $brand-tertiary;
                stroke: $brand-tertiary;
            }
        }
    }

    .modal-title{
        margin-top:0;
    }
}

.modal-container.visible {
    display: block;
    display: flex !important;
    height: 100%;
}

.modal-container.dashboard-widget-builder {
    @media screen and ( min-width:768px ) {
        //left: 300px;
    }

    @media screen and ( min-width:1024px ) {
        left: 0;
    }
}