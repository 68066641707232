
.trca-map-gauge{
	
	$gauge-bg: $base-dark;
	$shadow: 5px 5px 5px rgba(0,0,0,0.5);
	color:white;
	text-align:center;
	position: relative; //will get overwritten by absoulte in JS but just in case
	//background-color:white;
	
	// original font size 11

	a:link,
	a:visited{
		color:white;
		text-decoration: none;
	}
	a:hover,
	a:active,
	a:focus{
		text-decoration: underline;
	}

	$gauge-icon-width: 2.81em;
	$gauge-icon-height: 3.18em;
	> .gauge-icon {
		display: block;
		// original size of svg
		// width:31px;
		// height: 35px;

		width: $gauge-icon-width;
		height: $gauge-icon-height;

		filter: drop-shadow( $shadow );
	}


	.btn-link{

		svg{
			fill: white !important;
		}
		&:hover,
		&:active,
		&:focus{
			svg{
				fill:darken(white, 20%) !important;
			}
		}
	}

	&.stream{
		> .gauge-icon {
			// original size of svg
			// width:31px;
			// height: 35px;
			width: 2.81em;
			height: 3.18em;
		}
	}
	&.dam{
		> .gauge-icon {
			// original size of svg
			// width: 36px;
			// height: 34px;
			width: 3.27em;
			height: 3.09em;
		}
	}
	&.rain{
		> .gauge-icon {
			// original size of svg
			// width: 32px;
			// height: 36px;
			width: 2.9em;
			height: 3.27em;
		}
	}
	&.cluster{
		width:2.63em;
		height: 2.63em;
		overflow:hidden;
		&.gauge-clicked{
			overflow: visible;
		}
		
		> .gauge-icon {
			// original size of svg
			 // width: 97px;
			 // height: 45px;
			 position:absolute;
			width: 8.81em;
			height: 4.09em;
			left:-9001px;
			right:-9001px;
			bottom:0;
			margin:auto;
		}

		[data-name="cluster-expanded"]{
			display:none;
		}
	}

	[data-name="x"]{
		display:none;
	}

	[data-unresponsive] [data-name="x"]{
		display:inline;
	}
	
	[data-click-gauge],
	[data-click-cluster]{
		cursor: pointer;
	}
	



	.gauge-popup{
		display:none;
	    position: absolute;
		background-color:$gauge-bg;
		padding:0.8em 2em;
		bottom:4px;
		width:16em;
		left:-9001px;
		right:-9001px;
		margin:auto;
		cursor:default;
		box-shadow: $shadow;
		z-index: 100;


	}


	.precip-bar{
		box-sizing:content-box;
		width: $gauge-icon-width;
		padding-bottom:1.6em;
		margin-bottom:-1.5em;
	}

	&.cluster .precip-bar{
		display:none;
	}
	&.gauge-clicked.cluster .precip-bar{
		// adjust it over the cluster
		display:block;
		position: absolute;
		display: block;
	    position: absolute;
	    right: -3em;
	    top: -2.8em;

	}

	&.gauge-focus{
		z-index: 200;
	}

	&.cluster .gauge-right-pos{
		left: 121%;
	    right: auto;
	    bottom: 0.4em;
	}
	
	&.cluster .gauge-left-pos{
		right: 121%;
	    left: auto;
	    bottom: 0.4em;
	}

	.gauge-popup-title{
		text-transform: uppercase;
		margin-bottom:0.5em;
		margin-top:0;
		font-size:1.1em;
	}

	.guage-popup-value{
		font-size:1.6em;
		margin-bottom:0.3em;
		.icon{
			position:relative;
			top:0.1em;
			margin-left:0.5em;
		}
	}

	.guage-popup-id{
		margin-top:1em;
	}

	.gauge-popup-close{
		position:absolute;
		right:0.5em;
		top:0.5em;
	}

	$darkenby: 0%;
	$gauge-missed-color: darken(#454D4F, $darkenby);
	$gauge-high-high-color: darken(#E5242A, $darkenby);
	$gauge-high-color: darken(#F5881F, $darkenby);
	$gauge-low-color: darken(#FDB815, $darkenby);
	$gauge-normal-color: darken(#6DB345, $darkenby);
	$gauge-third-party: #7363B7;

	&.gauge-missed,
	.gauge-popup.gauge-missed{
		.gauge-popup{
			background-color: $gauge-missed-color;
		}

		[data-gauge-fill]{
			fill: $gauge-missed-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-missed-color;
		}

		svg{
			position: relative;
			z-index: 5;	
		}
	}
	.gauge-popup.gauge-missed{
		background-color: $gauge-missed-color !important;
	}
	.cluster-guage-icon.gauge-missed{
		[data-gauge-fill]{
			fill: $gauge-missed-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-missed-color;
		}
	}
	

	&.gauge-high-high{
		font-size:1.4em;
		.gauge-popup{
			background-color: $gauge-high-high-color;
		}

		[data-gauge-fill]{
			fill: $gauge-high-high-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-high-high-color;
		}

		svg{
			position: relative;
			z-index: 15;	
		}
	}
	.gauge-popup.gauge-high-high{
		background-color: $gauge-high-high-color !important;
	}
	.cluster-guage-icon.gauge-high-high{
		[data-gauge-fill]{
			fill: $gauge-high-high-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-high-high-color;
		}
	}
	

	&.gauge-high{
		font-size:1.2em;
		.gauge-popup{
			background-color: $gauge-high-color;
		}

		[data-gauge-fill]{
			fill: $gauge-high-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-high-color;
		}

		svg{
			position: relative;
			z-index: 10;	
		}


	}
	.gauge-popup.gauge-high{
		background-color: $gauge-high-color !important;
	}
	.cluster-guage-icon.gauge-high{
		[data-gauge-fill]{
			fill: $gauge-high-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-high-color;
		}
	}



	&.gauge-low{
		.gauge-popup{
			background-color: $gauge-low-color;
		}

		[data-gauge-fill]{
			fill: $gauge-low-color;

		}
	
		[data-gauge-stroke]{
			stroke: $gauge-low-color;
		}

		svg{
			position: relative;
			z-index: 6;
		}
	}
	.gauge-popup.gauge-low{
		background-color: $gauge-low-color !important;
	}
	.cluster-guage-icon.gauge-low{
		[data-gauge-fill]{
			fill: $gauge-low-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-low-color;
		}
	}




	&.gauge-normal{
		.gauge-popup{
			background-color: $gauge-normal-color;
		}

		[data-gauge-fill]{
			fill: $gauge-normal-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-normal-color;
		}

		svg{
			position: relative;
			z-index: 1;
		}		
	}

	.gauge-popup.gauge-normal{
		background-color: $gauge-normal-color !important;
	}
	.cluster-guage-icon.gauge-normal{
		[data-gauge-fill]{
			fill: $gauge-normal-color;
		}

		[data-gauge-stroke]{
			stroke: $gauge-normal-color;
		}
	}

	
	&.third-party-gauge{
		.gauge-popup{
			background-color: $gauge-third-party !important;
		}

		[data-gauge-fill]{
			fill: $gauge-third-party !important;
		}

		[data-gauge-stroke]{
			stroke: $gauge-third-party !important;
		}
	}


	.icon{
		fill:white;
		stroke: white;
		path[fill="none"]{
			fill: none;
			stroke: white;
		}
	}


}