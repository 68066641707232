
// Sets the paths for the SVGs to be changed with css
// Targets the spritesheet SVG loaded in the head with ajax 
.svg-icons-sheet{
	path {
	  fill: inherit;
	}

	path[fill="none"]{
		fill: none;
		stroke: inherit;
	}

}

.icon{
	fill: $brand-tertiary;
	stroke: $brand-tertiary;
	width:1em;
	height:1em;
}


// Container with an icon on the left
.icon-cont{

	position: relative;
	padding-left: 1.4em;

	.icon{
		position: absolute;
		left:0em;
		top: 0em;
		font-size: 1em;
	}

}

