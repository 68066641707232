.dam-gate-status{
	max-width: 380px;
	.dam-gate-status-title{
		font-size:0.9em;
		margin-top:0;
		margin-bottom: 1.1em;
	}

	.dam-gate-status-last-operation,
	.dam-gate-status-emergency{
		text-align: left;
	}

	.dam-gate-status-emergency{
		font-style: italic;
		margin-bottom:0.5em;
	}

	.dam-gate-status-table{
		text-align: center;
		margin-bottom:0.8em;
		th,td{
			border-top:1px solid grey;
			padding-left:1em;
			padding-right:1em;
			padding-top: 0.8em;
		    padding-bottom: 0.8em;
		}

		tr:last-child{
			th,td{
				border-bottom:1px solid grey;
			}
		}

	}


	.emergency-status-active{
		font-weight: bold;
	}
}