.dashboard-container{
    overflow: hidden;

    .dashboard-build-menu {
      display:none;

      @media screen and (min-width: $menu-go-big){
        display:flex;
      }
    }
}

.dashboard-build-menu{
    background-color: $base-light;


    .menu-item,
    .menu-item:link,
    .menu-item:visited{
        color: $base-darkest;
        background-color: $base-light;

         .icon{
            stroke: $base-darkest;
            fill:  $base-darkest;
        }
    }

    .menu-item:hover,
    .menu-item:active,
    .menu-item:focus{
        background-color: $base-darkest;
        color: $base-lightest;
        text-decoration: none;
        transition: color $trans-in ease-out, background-color $trans-in ease-out;

        .icon{
            stroke: $base-lightest;
            fill:  $base-lightest;
        }
    
    }

}

.dashboard-controls{
    opacity: 0;
    transition: opacity .4s ease-in;
    //transition: transform .25s linear;
    //transform: translate3d(0, -140px, 0); 
    

    @media screen and (min-width: $menu-go-big){
        .dashboard-build-menu,
        .dashboard-multi-menu{
            padding-top:0;
            padding-bottom:0;
            height:140px;
        }

        .dashboard-multi-menu{
            width:28em;
            padding-right: 0em;
            .menu-item{
                cursor: pointer;
                padding-top:50px;
                padding-left:20px;
                padding-right:20px;
            }
        }

        .dashboard-build-menu{
            width: calc( 100% - 28em );
            float:right;
            justify-content: flex-end;
        }

        .menu-buttons .menu-item-wrap{
            max-width: 180px;
        }

        .dashboard-build-menu{
            .menu-item{
                padding-top:35px;

            }
        }

         .dashboard-multi-menu{
            .menu-item{
                padding-top:50px;
            }
        }
    }

     @media screen and (min-width: $font-bp-one){
        .dashboard-build-menu,
        .dashboard-multi-menu{
             height:180px;
        }

        .dashboard-build-menu{
            .menu-item{
                padding-top:50px;
            }
        }


        .dashboard-multi-menu{
            .menu-item{
                padding-top:70px;
            }
        }
     }




    @media screen and (min-width: $slideout-goes-bigger){


        .dashboard-build-menu{
            .menu-item{
                padding-top:60px;
            }
        }

        .dashboard-build-menu{
            width: calc( 100% - 552px );
        }


        .dashboard-multi-menu{
            padding-left:2em;
            width: 552px;
             .menu-item-wrap{
            max-width: 130px;
             }

            .menu-item{
                padding-top:70px;
            }

            .menu-item-extra-spacing{
                margin-left:4em;
            }
        }

    }
}

.dashboard-controls.open{			

    opacity: 1;
    //transform: translate3d(0, 0, 0);
}