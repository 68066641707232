.data-explorer-map{
	height:500px;

	.station-svg{
		width:20px;
		height:20px;
		cursor: pointer;
		fill: #454d4f;
		filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
		transition:fill $trans-out ease-out;

		&.station-svg-out-of-range{
			//fill: $validation-color; ***disabled until phase 2 as per client request***
		}
		&:hover{
			fill: $brand-secondary;
			transition: fill $trans-in ease-out;

		}
	}
	
	$popout-side-padding: 2em;
	$popup-width: 18em;

	.station-popout{
		margin-top:-10px;
		position: absolute;
		background-color:white;
		left:100%;
		display:none;
		padding:1.5em 2em;
		font-size:1.2em;
		width:$popup-width;
		z-index: 10;
		box-sizing: content-box;


		.gauge-time{
			margin-top:0.2em;
			display:block;

			.outside-range{
				//color:$validation-color; ***disabled until phase 2 as per client request***
				color: $base-darkest;
			}
		}

		[data-gauge-close]{
			position:absolute;
			right:0.5em;
			top:0.5em;
		}

		&[class*=' show-rows-']{
		    .station-popout-sensor-list li{
		    	padding-right:2em;
		    	float:left;
		    }
		}
		@for $i from 2 through 5 {

			&.show-rows-#{$i}{
			
				width: $popup-width*$i;
				
				.station-popout-sensor-list li{
					&:nth-child(#{$i}n+#{$i + 1}){
						clear:left;
					}
				}
				
			}
			
		}

		.arrow-left {
		  width: 0; 
		  height: 0; 
		  border-top: 10px solid transparent;
		  border-bottom: 10px solid transparent;
		  border-right:10px solid white; 
		  position: absolute;
		  top:0;
		  bottom:0;
		  margin:auto;
		  right:100%;
		}

		.validation-message{
			//color: $validation-color; ***disabled until phase 2 as per client request***
			color: $base-darkest;
			margin-bottom:1em;
		}
	}

	.station-popout-station-name{
		font-size:1em;
		margin-top:0;
		margin-bottom:1em;
	}
	.station-popout-sensor-heading{
		font-size:1.1em;
		margin-top:0;
		margin-bottom:1em;
	}
	.station-popout-sensor-list{
		padding-left:0;
		margin-left:0;
		list-style: none;
		li{
			margin-bottom:0.9em;
			width:$popup-width;
		}
	}
	

	.station-poupout-add-all{
		clear:both;
		margin-bottom:1em;
		text-transform: uppercase;
		color:black;
	}
	.station-popout-last-update{
		display:block;
	}
	
	.gauge-btn{
		background-color: #D9F4FF;
		color:black;
		font-weight: normal;
		&:hover,
		&:focus{
			background-color: darken(#D9F4FF, 10%)
		}
	}

	.gauge-btn.gauge-selected{
		background-color: $brand-primary;
		&:hover,
		&:active,
		&:focus{
			background-color: lighten($brand-primary, 10%); 
		}
	}

	.gauge-btn.gauge-out-of-range{
		/*background-color: $btn-out-of-range-color;
		&:hover,
		&:active,
		&:focus{
			background-color: darken($btn-out-of-range-color, 10%); 
		}*/
	}

}

.submission-message-error{
	color: $validation-color;
	margin-bottom:3em;
}
.submission-message-success{
	color: $brand-secondary;
	margin-top: 2em;
    margin-bottom: 4em;
}

.report-btn {
	display: inline-block;
	border-radius: 0 !important;
	background-color: $base-color !important;
	height: 65px;
	margin-right: 25px;
}
