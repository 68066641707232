.dashboard-add-widget-modal-content {
    //padding: 0 1.2em 30px 1.2em;

    h2 {
        margin-top: 0 !important;
        max-width: 75%;
        
    }

    

    @media screen and ( min-width:768px ) {
        padding: 0 1.2em 30px 1.2em;
    }

    input[type="checkbox"] {
        border: 1px solid #42B8E9;
    }

    input[type="radio"] {
        border: 1px solid #42B8E9;
    }

    .widget-modal-save-btn {
        margin-top: 40px;
        //margin-bottom: 3em;
        
        button {
            position: fixed;
            left: 0;
            width: 100%;
            height: 70px;
            margin: 0;
            padding: 0;
            border-radius: 0;
            background-color: $base-color;
            bottom: 0;

            @media screen and ( min-width:768px ) {
                position: absolute;
                bottom: 0;
            }
        }
    }

    .checkbox-btn {
        font-weight: normal;
        font-size: 1em;
    }

    .station-dropdown-container {
        width: 100%;

        @media screen and ( min-width:768px ) {
            width: 60%;
        }

        @media screen and ( min-width:1024px ) {
            width: 70%;
        }

        .dropdown-validation-error {
            display: block;
            color: $validation-color;
            margin-top: 0.7em;
        }
        .all-stations.dropdown-validation-error{
            border-color: $validation-color;
        }

        .station-validation-msg {
            display: none;
        }

        .station-validation-msg.dropdown-validation-error {
            display: block;
        }

        .station-title.dropdown-validation-error {
            color: $validation-color;
        }

        .ui.dropdown .menu.transition {
            //display: none;
            position: inherit;
            border: none;
        }
        
    }

    .show-discharge-fieldset {
        margin-top: 1em;

        .form-field {
            margin-bottom: 0;
        }
    }

    .misc-widget-station-container {
        margin: 1em 0;
        margin-bottom: 200px;

        .station-title {
            display: block;
            margin-bottom: 20px;
        }
    }
}