.weather-radar-map-legend {
    border-radius: 3px;
    background-color: #2e2e2e;
    padding: 0 12px 0 10px;
    box-shadow: 2px 2px 5px 0px rgba(179,175,179,0.34);
    z-index: 10;
    width: 375px;

    @media screen and ( min-width:500px ) {
        position: absolute;
        right: 10px;
        top: 60px;
    }    
    
    img {
        display:block;
    }
}