.responsive-table-wrap.heat-map {
    margin-top: 3em;
    table {
        width: auto !important;
        border-collapse: collapse;
        thead {
            display: block;
            overflow: auto;
            tr {
                th {
                    width: 88px;
                    min-width: 88px;
                    padding: 8px !important;
                    background-color: $base-light;

                    &:first-child {
                        background-color: #fff;
                    }
                    border-left: solid 1px #fff;                    
                }

                &:last-child {
                    th {
                        background-color: $brand-primary;
                        
                        &:first-child {
                            background-color: #fff;
                        }
                    }
                }
            }

        }
        tbody {
            display: block;
            overflow-y: auto;
            overflow-x: hidden;
            height: 1000px;
            tr {
                td {
                    width: 88px;
                    min-width: 88px;
                    padding: 8px;
                    border-top: solid 1px $base-light;
                    border-left: solid 1px $base-light;
                }
            }
        }
    }            
}

