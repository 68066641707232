.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 200px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 110;
    display: none;

    .preloader-content-container {
        display: block;
        position: relative;
        top: 10%;
        margin-top: 20%;

        span {
            display: block;
            font-size: 20px !important; 
            color: #fff;
        }
        svg {
            display: block;
            margin: 0 auto;
            margin-top: -70px;
        }
    }
}

.preloader.active { 
    display: block; 
}