

.station-details{

	padding-top:1em;
	padding-bottom:1em;

	.primary-guage-installation,
	.primary-guage-installation{
		margin-bottom: 6em;
		font-size:0.8em;
	}
	

	.neighbouring-gauges-container,
	.battery-level-chart-container{
		max-width:1396px;
	}

	.gauge{
		font-size:0.8em;
		margin-top:3em;
		margin-bottom:3em;
	}
	
	.gauge .gauge-name{
		font-size:1.9em;

		a:link,
		a:visited{
			text-decoration: none;
		}

		a:hover,
		a:active,
		a:focus{
			text-decoration: underline;
		}
	} 
	

	// adjusting the icon section so it aligns with a single line
	.gauge-icon-section{
		padding-left:3.4em;

		.icon{
			top:0;
		}
	}

	.secondary-graph-container{

		@media screen and (min-width:$bp-sm){
			margin-top:3em;
		}
	}


	.gauge-photo-column,
	.gauge-station-dial-column,
	.gauge-dam-status-column{

		margin-top:2em;
		//margin-bottom:2em;

		.dam-calculator-btn-container {
			button {
				color: $brand-tertiary;
				background-color: #fff;
				text-transform: none;
				margin-left: 0;
				padding-left: 0;
				font-weight: normal;
			}
		}

		@media screen and (min-width: $bp-md){
			margin-top:1em;
		}
	}

	.dam-safety-code{
		margin-top:1.7em;
		margin-bottom:1.7em;
	}

	.previous-monthly-totals-table{
		font-size:0.8em;
		overflow-x:auto;

	    td:first-child,
	    th:first-child{
	      text-align: left;
	    }
	}

	@media screen and (min-width: $bp-md){
		.gauge-photo-column,
		.gauge-station-dial-column{
			padding-left:2em;
		}
	}

	.accumulated-totals-container table{
		font-size:0.8em;
	}

	.card-missed-data-overlay {
		display: none;
	}

	// add some padding for the graph on mobile
	@media screen and (max-width: $bp-sm ){
		.station-detail-primary .station-details-chart{
			padding-left:$wrap-small;
			padding-right:$wrap-small;
		}
		.primary-graph-container{
			border-left:2px solid grey;
			border-bottom:2px solid grey;
		}
	}

	@media screen and (min-width:$bp-sm){
		.gauge-download-links .icon-text-link{
			display:inline-block;
			margin-left:1.5em;

			&:first-child{
				margin-left:0;
			}

		}

		.station-detail-primary .gauge-identification{
			float:left;
			margin-right:3em;
			position: relative;
			top:-0.1em;
		}

		.modal-container {
			// .modal-content-container {
			// 	max-width: 90%;
			// }
		}


	}

	@media screen and (min-width: $bp-md ){
		.station-detail-secondary .gauge-identification{
			float:left;
			margin-right:3em;
			position: relative;
			top:-0.1em;
		}
	} 

	.primary-graph-container .js-plotly-plot{
		background: linear-gradient(white, #e7f8ff); /* Standard syntax */

	}

	.primary-graph-container .time-series-adjustment .js-plotly-plot .svg-container{
		position: 	relative;
		top:40px;
	}
}


.alarm-state .icon-check{
	fill: $brand-secondary;

}

.alarm-state .icon-cross{
	fill: red;
}

.station-image-selector .station-image .camera-image-img{
	cursor: pointer;
}
.station-image-controls{
	display:flex;
	gap:1em;
	justify-content: space-between;

	button[disabled]{
		cursor: default;
	}
}

