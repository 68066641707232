// Breadcrumbs HTML format taken from yoast
#breadcrumbs{
	margin-top:0.7em;
	margin-bottom:1.1em;
	@media only screen and (min-width: $bp-xs + 1px){
        font-size: 1em;
        margin-bottom: .7em;
    }

    a:link,
    a:visited{
    	//color: $grey-light;
        color: $base-color;
    }

    a:hover,
    a:active{
    	//color: darken($grey-light, 10)
        color: darken($base-color, 10);
        text-decoration: underline;
    }
}