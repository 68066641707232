
.footer{
    position: relative;
    background-color:$base-dark;
    color:#c9cbcc;
    padding-top:2.65em;
    padding-bottom:2em;
    z-index: 11;


    font-size:0.7em; //used to match the padding on the inside of the content

    button{
        font-size:1em;
    }

    a:link,
    a:visited,
    .btn-link{
        color:#c9cbcc;
        text-decoration: underline;
    }

    a:hover,
    a:active,
    a:focus,
    .btn-link:hover,
    .btn-link:active,
    .btn-link:focus{
        color:#ebedef;
        text-decoration: none;
    }

    ul.footer-menu{
        padding:0;
        margin-left:0;
    }

    li{
        list-style-type:none;
    }

    p, li{
        margin-bottom:0.5em;
    }

    .contact-section{
        span{
            display:block;
        }
    }

    .footer-section{
        margin-bottom:1.95em;


    }

    .footer-section-heading{

        font-size: 1.5em;
        text-transform: capitalize;
        font-weight:700;
        margin-top:0;
        margin-bottom: 0.75em;
        font-family: $font-primary;
        color:white;

    }

    .footer-subheading{
        font-size: 1.1em;
        font-weight:bold;
        margin-top:0;
        margin-bottom:0.4em;
    }

    .top-copy{
        display:none;
    }
    .bottom-copy{
        display:block;
    }

    .dashboard-menu-desktop{
        display:none;
    }

    .mailing{
        margin-bottom:1em;
    }

    @media screen and (min-width: $bp-md){
        .footer-menu-left{
            clear:left;
            float:left;
            width:50%;
            max-width:300px;
        }

        .footer-menu-right{
            float:left;
            width:50%;
            max-width:300px;
        }

        .footer-social{
            clear: both;
        }

       
    }


    @media screen and (min-width: $bp-xl){

         .dashboard-menu-desktop{
            @include clear;
            padding-bottom:1.8em;
            border-bottom:1px solid #a0a4a5;
            margin-bottom:1.8em;

            .menu-item{
                float:left;
                width:50%;

                &:nth-child(2n+3){
                    clear:left;
                }
            }
        }

        .footer-xl-left{
            width:45%;
        }

        .footer-xl-right{
            width:50%;
            float:right;
        }

        .dashboard-menu-mobile{
            display:none;
        }
        .dashboard-menu-desktop{
            display:block;
        }
        
        .footer-section-center{
            padding-left:3.5em;
        }

        .top-copy{
            display:block;
        }
        .bottom-copy{
            display:none;
        }

        .footer-section-center{
            left:0;
            width: 75%;
        }
    }

    .footer-social{
        @media screen and (max-width: $bp-lg - 1px){
            position: relative;
            top:0.5em;
        }

        @media screen and (min-width:$bp-lg){        
            margin-top:0.5em;
            margin-bottom:1.5em;
        }
        .icon{
            fill:white;
            font-size:2em;
            &:hover,
            &:active{
                fill:darken(white,20%);
            }
        }
    }


}

.contact{
    .contact-heading{
        font-size:1em;
        font-weight: normal;
        //margin:0 0 0.5em;
        margin:0;
    }
    .office{
        margin: 1.25em 0;

    }
    .phone{

    }
}