.piped-nav{
    list-style:none;
    padding-left:0;
    margin-left:0;

    &.no-pipe{
        .piped-nav-item{
            &:after {
                content: " ";
            }
        }
    }

    .piped-nav-item{
        display:inline-block;

        &:after {
            content: "|";
            color: #000;
            padding: 0 0.4em;
            text-decoration: none;
        }

        &:last-child{
            &:after{
                display:none;
            }
        }
    }
    a {
        cursor:pointer;
        &:link{
            text-decoration:none;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    a.active {
        color: #000;
        font-weight: bold;
    }

}