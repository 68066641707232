$slideout-hoz: 500px; // defined in JS as well
$slideout-btn-size: 40px;
$map-height: 517px;
$slideout-bg: $base-light;
$slideout-content-gets-pushed: 1105px; 


//background-color:white;
.slideout-panel{
    background-color: $slideout-bg;
   
    .slideout-content{
        padding-top:1.5em;
        padding-bottom:1em;
        @include container-padding;
    }

    label{
        display: block;
        margin-left: 38px;
        margin-bottom: 10px;

        input {
            margin-left: -38px;
        }
    }

    .form-field {
        margin-bottom:0;
    }

    &.slideout-closed{
        .slideout-content{
            display:none;
        }
    }

}

.slideout-panel-wrap{
    position: relative;
}

.slideout-controls{



    .slideout-button{

        height:$slideout-btn-size;
        width:100%;
        background-color: #D7DADB;
        color: $base-light;
        padding:0;
        min-width:0px;
        border-radius: 0;
        
        .icon{
            fill: $base-dark;
        }

        .icon-chev-up,
        .icon-chev-right,
        .icon-chev-left,
        .slideout-opened-text{
            display:none;
        }
        
        //show and hide the appropriate elements based on if the slide is hoz or vert
        @media screen and (max-width: $slideout-hoz - 1px){


            &.slideout-open{

                .slideout-opened-text,
                .icon-chev-up{
                    display:inline-block;
                }
                .slideout-closed-text,
                .icon-chev-down{
                    display:none;
                }
            }
        }

        @media screen and (min-width: $slideout-hoz){

            .icon-chev-down{
                display:none;
            }

            .icon-chev-right{
                display:inline-block;
            }

            &.slideout-open{
                
                .slideout-opened-text,
                .icon-chev-left{
                    display:inline-block;
                }
                .slideout-closed-text,
                .icon-chev-right{
                    display:none;
                }

            }
        }
    }
}

@media screen and (min-width: $slideout-hoz){


    .slideout-panel-wrap{
        display:flex;
    }

    .map-panel{
        position:absolute;
        left: $slideout-btn-size;
        width: calc(100% - #{$slideout-btn-size});
        height:100%;
    }

    .slideout-panel{
        position: relative;

        padding-right:$slideout-btn-size;
        width:$slideout-btn-size;
        z-index: 10;
        background-color:white;
        transition: transform 300ms ease-in-out;


        + .slideout-main-content-wrap{
            width: calc(100% - #{$slideout-btn-size});
            margin-left:auto;
            transition: padding-left 300ms ease-in-out;
        }

        &.slideout-open{
            transform: translate3d($slideout-distance,0,0);
            transition: transform 300ms ease-in-out;

            + .slideout-main-content-wrap{
                //padding-left: $slideout-distance;
                transition: padding-left 300ms ease-in-out;

                @media screen and (min-width: $slideout-content-gets-pushed ) {
                    padding-left: $slideout-distance;
                }
            }

        }
    }

    
    .slideout-controls{
        height:100%;
        width:$slideout-btn-size;
        position: absolute;
        right:0;
        top:0;

        .slideout-button{
            position: absolute;
            left:0;
            height:100%;
            width:$slideout-btn-size;
        }
    }

    .slideout-content{
        position:relative;
        left:$slideout-distance * -1;
        height:100%;
        width: $slideout-distance;
        overflow-y: auto;
        display:block !important;
        background-color: $slideout-bg;
    }

    .slideout-main-content-wrap{

        background-color:white;

        .page-title{
            margin-top:1.3em;
        }

    }

}

@media screen and (min-width: $slideout-goes-bigger){

    .slideout-panel{
        + .slideout-main-content-wrap .container-fluid{
            padding-left: 100px - $slideout-btn-size;
        }

        &.slideout-open{
            transform: translate3d($slideout-disance-bigger,0,0);

            + .slideout-main-content-wrap{
                padding-left: $slideout-disance-bigger;
   
            }

        }
    }

    .slideout-content{
        padding-left:$slideout-left-padding-bigger !important;
        width: $slideout-disance-bigger;
        left: $slideout-disance-bigger * -1;


    }

}


.slideout-heading{
    font-size:1em;
    margin-top: 2.1em;
    font-weight: bold;
    clear:both;
    text-transform: uppercase;
}

.slideout-legeond-first{
    margin-top:2.1em;
   
}