.icon-text-link {
    svg {
        display: inline-block;
        margin-right: 0.4em;
        width:0.9em;
        height:0.9em; 
        position: relative;
        top:0.1em;
    }

    a{
    	&:link{
    		text-decoration: none;
    	}

    	&:hover,
    	&:active,
    	&:focus{
    		text-decoration: underline;
    	}
    }

}