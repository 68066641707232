.radio-button-series {
    margin-top: 2em;

    legend {

        font-size: 1.1em;
        margin-bottom:0.7em;
    }

    .form-field {
        float: left;
        margin-bottom: 0;
        max-height: 180px;
        span {
            display: block;
            margin-bottom: .5em;
        }

        input {
            -ms-transform: scale(1.7); /* IE 9 */
            -webkit-transform: scale(1.7); /* Chrome, Safari, Opera */
            transform: scale(1.7);
            margin-right: 15px;
        }
    
        .radio-btn {
            font-size: 1em;
            font-weight: normal;
            margin-right: 2.8em;
            border-color: $brand-tertiary; 
        }
    
        .radio-btn.large-gap-top {
            height: 75px;
            margin-top: 3em;
            margin-right: 3em;
        }
    }

    .validation-message {
        color: #db0303;
        margin-top: 0.7em;    
    }
}