@charset "UTF-8";

$notification-close-color: white !default;

.notification{
	text-align: left;
	min-height: 36px;
	//margin-top: -36px;
	padding: 10px;
	color: #141414;
	font-weight: bold;
	transition: opacity .3s ease-in-out;
	background-color: #97dcf9;

	@media screen and ( min-width: 768px ) {
		text-align: center;
	}

	.icon {
		display: inline-block;
		float: left;
		margin-right: 5px;
		padding-top: 8px;
		fill: #141414;
		stroke: #141414;

		@media screen and ( min-width: 768px ) {
			display: inline-block;
			float: none;
			margin-left: 10px;
		}
	}

	p {
		display: block;
		margin:0;
		padding-top: 5px;
		font-weight: 300;
		font-size: .8em;
		line-height: 1.35em;

		a:link,
		a:visited {
			display: inline-block;
			padding-left: 10px;
			color: #141414;
		}

		a:hover,
		a:active,
		a:focus{
			color: darken(#141414, 10%);
		}

		@media screen and ( min-width: 768px ) {
			display: inline-block;
			float: none;
		}
	}
}

