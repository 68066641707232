

.select-gauge-dropdown{

	$border-width: 2px;

	.select-gauge-dropdown-input-warp{
		$btn-width: 4em;
		position: relative;
		border-radius: 0.25em; 
		border:$border-width solid black;

		.select-gauge-dropdown-input{
			max-width:none;
			padding-right: $btn-width;
			border-radius: 0.25em; 
			margin-bottom:0;
			//outline:none;
			box-shadow: none;
			border:none;
			transform-origin: 50% 50%;
		}

		.select-gauge-dropdown-toggle-btn{
			position:absolute;
			right:0;
			top:0;
			height:100%;
			background-color:transparent;
			font-size:0.8em;
			color: black;
			text-transform: capitalize;
			border-radius: 0;
			font-weight:normal;

			.icon {
				margin-left:1em;
				position:relative;
				top:0.1em;
				fill:black;
			}

		}
	}

	.select-gauge-dropdown-list{
		display:none;
	}


	&.dropdown-open{

		 .select-gauge-dropdown-list{
			display:block;
		}

		.select-gauge-dropdown-toggle-btn{
			background-color: $brand-tertiary;

			.icon{
			    transform: rotate(180deg);
				transform-origin: 50% 50%;
			}
		}

		.select-gauge-dropdown-input-warp{
			border-color: $brand-tertiary;
		}
	}


	.select-gauge-dropdown-toggle-btn{



		&:hover,
		&:focus{
			background-color: $brand-primary;
		}
	}

	.select-gauge-dropdown-list{
		list-style: none;
		padding:0;
		margin:0;
		border-left:$border-width solid $brand-tertiary;
		border-right:$border-width solid $brand-tertiary;
		border-bottom:$border-width solid $brand-tertiary;
		font-size:0.8em;

		ul{
			padding:0;
			margin:0;
			list-style: none;
		}

		li{
			padding:0;
			margin:0;
		}

		
		.select-gauge-dropdown-row{
			border-color: #e8eeef;
			border-bottom: 1px solid #EBEBEB;

			&.station-open{
				background-color: #FCFCFC;
			}

			&:last-child{
				border-bottom:none;
			}

			&.station-selected{
				background-color:#ffd87b;
			}
		}



		.select-gauge-dropdown-title{
			position: relative;
			text-transform: uppercase;
			padding: 1em 2em 1em 1em;
			transition: background-color $trans-out ease-out;

			&.has-gauge-outside-range{
				//font-weight:bold; ***disabled until phase 2 as per client request***
				//color: $validation-color; ***disabled until phase 2 as per client request***
				color: $base-darkest;
			}

			&:hover{
				background-color:$base-light;
				transition: background-color $trans-in ease-out;
				cursor: pointer;

				&.none-available{
					background-color:white;
					cursor:default;
				}

				.select-gauge-dropdown-plus{
					background-color: $brand-primary;
				}
			}
			
		}

		.select-gauge-dropdown-plus{
			
			min-width:0;
			padding: 0;
			width: 1.5em;
			height: 1.5em;
			line-height: 1.55em;
			border-radius: 5px;
			background-color: #30383a;

			position:absolute;
			right: 1em;
			top:1.5em;
			bottom:1.5em;
			margin:auto;

			&:hover,
			&:focus{
				background-color: $brand-primary;
			}

		}	

		.select-gauge-dropdown-content{
			display:none;
		}

		.station-open .select-gauge-dropdown-content{
			display:block;
			padding: 0em 1em 1em 1em;
		}

		.select-gauge-dropdown-sensor-title{
			margin-top:0em;
			margin-bottom:0.6em;
		}


		.gauge-button-wrap{
			display:inline-block;
			margin-right:1.5em;
			margin-bottom:1em;
			vertical-align: top;
		}

		.gauge-button-description{
			display:block;
			font-size:0.8em;
			margin-top:0.5em;
		}

		.gauge-time .outside-range{
			//color: $validation-color;
			color: $base-darkest;
		}

		.select-gauge-dropdown-gauge-btn{
			background-color:#D9F4FF;
			font-size:0.8em;
			font-weight: normal;
			color: black;

			&:hover,
			&:focus{
				background-color: darken( #D9F4FF, 10%);
			}

			&.gauge-selected{
				background-color: $brand-primary;

				&:hover,
				&:focus{
					background-color: lighten( $brand-primary, 10%);
				}
			}
		}

		.gauge-out-of-range .select-gauge-dropdown-gauge-btn.gauge-selected{
			/*background-color:$btn-out-of-range-color; ***disabled until phase 2 as per client request***
			background-color:#D9F4FF;
			&:hover,
			&:focus{
				background-color: darken( $btn-out-of-range-color, 10%); 
				background-color:darken( #D9F4FF, 10%); 
			}*/
		}

		.select-gauge-dropdown-add-all-btn{
			font-size:0.8em;
			background-color:transparent;
			color:black;
			font-weight: bold;
			margin-left:1em;
			&:hover,
			&:active,
			&:focus{
				text-decoration: underline;
			}
		}

		
	}
}