body,
button,
input,
select,
textarea {

	color: $base-darkest;
	font-family: $font-primary;

	font-size: 18px;
	font-size: 1.8rem;
	line-height: 1.5;

	 @media screen and (min-width: $font-bp-one){
        font-size:20px;

    }

}


body,
html{
	background-color:$base-dark;
	overflow-x: hidden;
	overflow-y: auto;
}

body.disable-scroll {
	position: fixed;
	overflow: hidden; 
	width: 100%;
}


@mixin focus(){
    outline: 3px solid rgba(43, 171, 226,0.5);
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

a:focus,
textarea:focus,
input:focus,
button:focus {
    @include focus();
}

a:active,
textarea:active,
input:active,
button:active { 
    outline: none; 
}




h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-weight: 400;
    line-height: 1.28;
    margin-top:2.1em;
    margin-bottom:24px; //pixel value to match p tags
}

h1,
.h1{ 
    text-transform: uppercase;
    font-size:1.8em;
    font-family: $font-secondary;
    font-weight: 700;
    
    &.larger{
        font-size: 2.75em;
    }

    @media screen and (min-width: $bp-md){
        font-size:2.4em;
    }
}

h2,
.h2{ 
    font-size: 1.5em;
    font-family: $font-secondary;
    font-weight: 700;
}

h3,
.h3{ 
    font-size:1.3125em;
    font-family: $font-secondary;
    font-weight: 700;
}

h4,
.h4{
    font-size: 1.5em;
    font-weight: 300;
}

h5,
.h5{
    font-size:1.3125em;
    font-weight: 300;
}

h6{
    font-size:1.2em;
    font-weight: 300;
}


p {
	margin-bottom: 24px; //set in px so em changes do not effect spacing
	line-height: 1.6;
}

p.larger{
	font-size:1.1em;
}

p.small{
	font-size:0.9em;
}


b, strong {
	font-weight: bold;
}
dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	font-size: 0.9em;
	margin: 1.4em 0;
	padding:1.3em;
	border-left:8px solid $brand-tertiary;
	background-color: $base-light;

	
	:first-child{
		margin-top:0;
	}
	:last-child{
		margin-bottom:0;
	}

}
address {
	margin: 0 0 1.5em;
}
pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 15px;
	font-size: 1.5rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}
code, kbd, tt, var {
	font: 15px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}
abbr, acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}
mark, ins {
	background: $base-light;
	text-decoration: none;
}
sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	bottom: 1ex;
}
sub {
	top: .5ex;
}
small {
	font-size: 75%;
}
big {
	font-size: 125%;
}

/*--------------------------------------------------------------
 Elements
--------------------------------------------------------------*/
hr {
	background-color: $base-dark;
	border: 0;
	height: 1px;
	margin-top: 1.5em;
    margin-bottom: 1.5em;
}
ul, ol {
	margin: 0 0 1.2em 1.2em;
}
ul {
	list-style: disc;
}
ol {
	list-style: decimal;
}

li{
	margin-top:0.3em;
	margin-bottom:0.3em;
}
li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}
  dt {
    font-weight: bold;
	color:$base-darkest;
  }
  dd {
    margin: 0;
    padding: 0 0 0.9em 0;
  }
img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}
figure {
	margin: 0;
	margin-bottom: 1.4em;
	img{
		display:block;
	}
	figcaption{
		padding:0.8em 0;
		font-size:0.9em;

		h1,h2,h3,h4,h5,h6{
			font-family:$font-primary;
			margin-top:0;
			margin-bottom:0.2em;
			font-size:1em;
		}
	}

}

table {
	margin: 0 0 1.5em;
	width: 100%;
}
th {
	font-weight: bold;
}


@mixin clear{

	&:before,
	&:after{
		content: '';
		display: table;
	}

	&:after{
		clear: both;
	}
}


.clear:before,
.clear:after{
	content: '';
	display: table;
}

.clear:after{
	clear: both;
}



.content-wrap{
	background-color:white;

	.container-fluid{
		padding-left:$wrap-small;
		padding-right:$wrap-small;
		max-width: $content-max-width;
		padding-bottom:2em;
		
		@media screen and (min-width: $bp-sm) {

			margin:0 auto;
			
			padding-left:$wrap-med;
			padding-right:$wrap-med;
		}
	}
}

.content-wrap-full{

	background-color:white;

	.container-fluid{
		padding-left:$wrap-small;
		padding-right:$wrap-small;
		max-width: $site-max-width;
		padding-bottom:2em;
		
		@media screen and (min-width: $bp-sm) {

			margin:0 auto;
			
			padding-left:$wrap-med;
			padding-right:$wrap-med;
		}
	}

}

.site-content-wrap{
	background-color:white;

	.container-fluid{
		max-width: none;
	}

	@media screen and (min-width: $slideout-goes-bigger){
		.container-fluid{
			padding-left:$slideout-left-padding-bigger;
			padding-right:$slideout-left-padding-bigger;
		}
	}
	
}


.wrap-inner{
	padding-left:$wrap-small;
	padding-right:$wrap-small;

	@media screen and (min-width: $bp-sm) {

		padding-left:$wrap-med;
		padding-right:$wrap-med;
	}
}

.wrap-outter{
	margin-left:$wrap-small;
	margin-right:$wrap-small;

	@media screen and (min-width: $bp-sm) {

		margin-left:$wrap-med;
		margin-right:$wrap-med;
	}
}


.container-fluid{
	@include container-fluid;
	padding-left:$wrap-small;
	padding-right:$wrap-small;
}



/*--------------------------------------------------------------
	Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers */
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0px 0px, 0px 0px,0px 0px, 0px 0px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar */
    clip-path: none;
}

a.skip-to-main {
	left:-999px;
    position:absolute;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;
}

a.skip-to-main:focus, a.skip-to-main:active {
    color: #fff;
    background-color:#000;
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow:auto;
    margin: 10px 35%;
    padding:5px;
    border-radius: 15px;
    border:4px solid $brand-primary;
    text-align:center;
    font-size:1.2em;
    z-index:999;
}