$omni-background: $brand-tertiary;
$omni-text: white; 


.omni-back-top{
	background-color: rgba($omni-background, 0.6);
	color: $omni-text;
	border-radius: 3px;
	bottom: 60px;
	opacity: 0;
	padding: 8px 16px 8px 18px;

	position: fixed;
	right: -60px;
	transition: all 0.2s ease-in-out 0s;
	z-index: 100;
	font-size: 18px;
	cursor: pointer;

    &.present{
    	right: 22px;
    	opacity: 0.95;
    }

    &.present:hover{
    	background-color:$omni-background;
    	opacity: 0.95;
    }

    .icon{
    	fill: $omni-text;
    }

}


@media screen and (max-width: $bp-sm) {
	.omni-back-top{
		bottom:15px;
		&.present{
	    	right: 15px;
	    	opacity: 0.95;
    	}
	}
}