.dam-operations-content-container {

    .modal-button {
        background-color: #fff;
        color: #2babe2;
        text-transform: capitalize;
        font-weight: 400;
        padding: 0;
        margin: 10px;

        &:hover {
            text-decoration: underline;
        }
    }
    

    .dam-safety-code-selector {
        .dam-safety-code-selector-modal {

            .dam-status-selector {
                margin-top: 1.5em;
                margin-bottom: 2em;
            }

            .update-status-btn {
                border-radius: 0 !important;
                background-color: #454d4f !important;
                height: 65px;
                margin-right: 25px;
            }

            .station-dropdown-container {
                margin-top: 1.5em;
                margin-bottom: 1.5em;
                width: 70%;
                border-radius: 0.25em;

                h2 {
                    margin-top: 1.5em;
                }

                .dropdown-validation-error {
                    display: block;
                    color: $validation-color;
                    margin-top: 0.7em;
                }
                .all-stations.dropdown-validation-error{
                    border-color: $validation-color;
                }
        
                .station-validation-msg {
                    display: none;
                }
        
                .station-validation-msg.dropdown-validation-error {
                    display: block;
                }
        
                .station-title.dropdown-validation-error {
                    color: $validation-color;
                }
        
                .ui.dropdown .menu.transition {
                    //display: none;
                    position: inherit;
                    border: none;
                }

                .selected-dam {
                    text-transform: capitalize;
                }
            }
        }
    }
}

    .station-data-row {
        margin-top: 3em;


        &:nth-child(3) {
            margin-top: 1em;
        }

        h2 {
            display: block;
            margin-top: 1em;
            margin-right: 3.5em;
            text-transform: capitalize;

            @media screen and (min-width: 450px ){
                display: inline-block;
            }
        }   
        
        .dam-calculator-link {
            text-decoration: none;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    
        .gate-openings-container {
            margin-top: 2.5em;

            @media screen and (min-width: 1200px ){
                //padding-left: 3em;
            }


        }
    
        .current-safety-code-container {        
            width: 100%;
            margin-top: 1em;

            .safety-code-label {
                display: block;
                height: 45px;    
                margin-right: 1em;
                margin-top: 15px;
            }

            a {
                display: inline-block;
                margin-top: 20px;
                margin-left: 30px; 
                cursor: pointer;
            }
            
            .dam-safety-container {

                .safety-code-icon-container {
                    position: relative;
                    float: left;
                    padding: 13px 23px 13px 13px;
                    border: 1px solid #000;
        
                    .safety-icon {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        vertical-align: middle;
                    }
        
                    span {
                        display: block;
                        margin-left: 0;
                        padding-top: 10px;
                        line-height: 1em;
                        font-size: 1em;
                        //max-width: 198px;
                        vertical-align: middle;

                        @media screen and (min-width: 768px ){
                            display: inline-block;
                            margin-left: 15px;
                            padding-top: 0;
                        }
                    }
    
                    .arrow-dropdown-btn {
                        display: inline-block;
                        background-color: #fff;
                        min-width: 20px;
                        min-height: 20px;
                        width: 20px;
                        height: 45px;
                        padding-left: 35px;
                        padding-right: 25px;
                        vertical-align: middle;
                        .arrow-down {
                            width: 0; 
                            height: 0;
                            margin-left: -20px; 
                            border-left: 15px solid transparent;
                            border-right: 15px solid transparent;
                            border-top: 20px solid $brand-tertiary;
                        }

                        @media screen and (min-width: 768px ){
                            margin-left: 2em;
                        }
                    }
                }
            }
        }
    }
    
.dam-safety-code-logs {
    clear: both;
    padding-top: 2em;
    margin-right: 3em;

    span {
        display: block;
        padding-bottom: 1em;
    }

    .safety-log {
        display: block;
        border-top: 1px solid #000;
        overflow: auto;
        padding-bottom: 10px;
        vertical-align: middle;

        &:last-child {
            border-bottom: 1px solid #000;
        }

        .date-category-container {
            float: left;
            width: 100%;

            @media screen and (min-width: 1024px ){
                width: 30%;
            }

            .date-last-modified {
                padding-top: 10px;
                padding-bottom: 5px;
            }

            .safety-icon-status {
                .safety-icon {
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;
                    vertical-align: middle;
                }

                .category {
                    display: inline-block;
                    font-weight: bold;
                    max-width: 180px;
                    vertical-align: middle;
                    margin-right: 0.8em;
                    padding-bottom: 0;
                }
            }
        }

        .log-creator {
            width: 100%;
            display: block;
            float: left;
            padding-top: 10px;
            height: 50px;

            @media screen and (min-width: 1024px ){
                width: 25%;
                height: auto;
            }
        }

        .log-reason {
            width: 100%;
            display: block;
            float: left;
            padding-top: 10px;

            @media screen and (min-width: 1024px ){
                width: 45%;
            }
        }
    }
}

.safety-icon{
    // a default colour
    background-color: black;
}

.safety-icon.green {
    background-color: green;
}

.safety-icon.blue {
    background-color: blue;
}

.safety-icon.yellow {
    background-color: yellow;
}

.safety-icon.orange {
    background-color: orange;
}

.safety-icon.red {
    background-color: red;
}


.dam-ops-load-more{
    margin-top:2em;
    text-align: center;
}



// dam operation create
.dam-gate-status {
    border: 1px solid #000;
    padding: 30px;
    width: 100%;
    max-width: 440px;
}

.gate-operation-form-submit{
    margin-top:1em;
    margin-bottom:2em;
}

.operation-step-fieldset {

    padding-top: 2em;
    padding-bottom: 0.6em;
    border-top: 1px solid black;

    @media screen and (min-width:550px){
        margin-right: 3.5em;
    }

    &:first-child{
        margin-top: 2.5em;
    }

    &:last-child{
        margin-bottom:3.4em;
        border-bottom:1px solid black;
    }
}

.operation-step-fieldset .operation-step-legend{
    margin-bottom:0em;
}


.dam-operation-add{
    @media screen and (min-width:1200px){
        display:flex;
        flex-direction: row-reverse;
        max-width: 1200px;
        .gate-openings-wrap{
            width:40%;
        }
        .gate-operation-form-warp{
            width:60%;
        }
    }

}

.flatpickr-calendar{
    select,
    input{
        box-shadow: none;
    }
}

.gate-operation-form-warp{
    .success-message{
        color:green;
        margin-top:1.5em;
    }
}
.gate-operation-form{
    margin-top:2.9em;

    .step-controls {
        .btn-link{
            margin-right:3em;
            margin-bottom: 1.6em;
        }
    }


    .radio-button-series .form-field {
        margin-left:0.3em;
    }

    .gates-operated .radio-button-series .form-field {
        float:none;
        
        margin-bottom:0.5em;
    }

    @media screen and (min-width:700px){
        .type-time-wrap{
            .form-field{
                float:left;
                &:first-child{
                    margin-right:2em;
                }
                select{
                    font-size: 1.05em;
                    padding-top: 9px;
                    padding-bottom: 10px;
                }
            }

          
        }

        .amount-wrap{
            clear:both;

            
        }

        .amount-wrap{
            .form-field{
                float:left;
                &:first-child{
                    margin-right:2em;
                }
            }
                .radio-button-series {
                margin-top:0;

            }
            .radio-button-series legend{
                opacity:0;
                margin-bottom: 1em;
            }


        }

        .step-controls{
            clear:both;
        }
    }
}

.dam-operation-logs .piped-nav-item{
    margin-right:1em;
}


.dam-status-summary-item{
    clear:both;
    position:relative;
    padding-left:2.4em;
    display:inline-block;
    margin-right:5em;
    margin-bottom:1.5em;
    .safety-icon{
        display:block;
        width:1.8em;
        height:1.8em;
        position:absolute;
        left:0;
        top:0.2em;
        bottom:0;
        margin-top:auto;
        margin-bottom:auto;
    }
    .dam-status-summary-title{
        display: block;
    }   
    .dam-status-summary-status{
        display:block;
        font-size:0.7em;
    }
}

.readings-safetycode-wrap{
    .readings{
        font-size:0.9em;
    }
    .gauge-date,
    .gauge-id{
        display:inline-block;
        margin-right:2em;
    }

    @media screen and (min-width:1500px){
        display:flex;
         .readings{
            width:100%;
         }
         .current-safety-code-container{
            margin-top:-1em;
            padding-left:2em;
         }
    }

}

.dam-current-water-level{
    border:1px solid grey;
    padding: 1.5em 2em;
    display: inline-block;
    margin-top:2em;

    .dam-current-water-level-title{
        font-weight: bold;
    }
}
