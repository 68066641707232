.dam-safety-code{
	
	.dam-safety-code-color{
		display:inline-block;
	    width: 2em;
	    height: 2em;
	    margin-left: 0.8em;
	    margin-right: 0.7em;
	    margin-bottom: -0.7em;
	}

	.dam-safety-code-label{
		font-weight: bold;
	}
	
}