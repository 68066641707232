
.gauge{
	font-size:0.7em;

	.gauge-name{
		font-size: 1.3em;
		font-family: $font-primary;
		margin-top: 0;
    	margin-bottom: 0.2em;

    	a:link,
		a:visited{
			color:$base-darkest;
		}

		a:hover,
		a:active,
		a:focus{
			color: darken($base-darkest, 20%);
		}
	}

	.gauge-date,
	.gauge-id{
		display:inline-block;
		
	}
	.gauge-date{
		margin-right:1.3em;
	}

	.gauge-icon-section{
		padding-left:3.7em;
		position: relative;
		.icon{
		    width: 2.3em;
		    height: 2.3em;
		    position: absolute;
		    left: 0em;
		    top: 0.3em;
		}
	}
}


.card-gauge{
	// Reset padding to graph can goto the outside
	padding:0;
	min-height:320px;
	position: relative;

	// Set padding for content area
	.card-gauge-content{
		padding:$card-padding;

		.dam-safety-container {
			position: absolute;
			right: $card-padding;
			top: $card-padding;
			width: 25px;
			height: 25px;
		}

		.dam-safety-container.red {
			background-color: red;
		}

		.dam-safety-container.blue {
			background-color: blue;
		}

		.dam-safety-container.yellow {
			background-color: yellow;
		}

		.dam-safety-container.orange {
			background-color: orange;
		}

		.dam-safety-container.green {
			background-color: green;
		}


	}

	// Height must be set on the element for it to work with plotly
	.card-gauge-graph{
		height: 150px;
		width:100%;
		position: absolute;
		bottom:0;
		left:0;
	}

	.card-transparent-overlay {
		display: block;
		position: absolute;
	    height: 80%;
	    width: 100%;
	    top: 4.5em;
		left: 0;
		background-color: rgba(255, 255, 255, 0);

		@media screen and (min-width: 1367px) {
			display: none;
		}
	}


	.card-missed-data-overlay{
		position: absolute;
	    height: 100%;
	    width: 100%;
	    padding-top: 1.5em;
	    text-align: center;
	    top: 0;
	    left: 0;
	    font-size: 3em;
		background-color: rgba(255, 255, 255, 0.9);
		display: none;
	}

	.card-missed-data-overlay.visible {
		display: block;
		
		a:link,
		a:visited,
		a:hover,
		a:focus{
			color: $base-darkest;
		}
	}
	
}

.card-gauge.minimalized {
	.card-gauge-graph{
		height: 290px;

	}
}

.gauge-section
{
	margin-top:0.7em;
	margin-bottom:0.8em;
}
	


.guage-reading{

	padding-left:0;
	margin-left:0;
	margin-top:0.7em;
	margin-bottom:0.8em;
	list-style:none;

	@include clear;


	.gauge-reading-time,
	.gauge-reading-value{
		display:block;
		line-height: 1.3;
	}

	.gauge-reading-value{
		font-size:1em;

	}

	.gauge-reading-time{
		font-size: 0.9em;
	}

	.guage-reading-item{

		float:left;
		margin-right: .6em;

		&.missing-values{
			.gauge-reading-time{
				font-weight: bold;
				color: red;
			}
		}

		&:last-child{
			margin-right:0;
		}

		&:first-child{

			.gauge-reading-value{
				font-size:1.7em;
			}
			
			// move other items down to match the height of the larger text of the first gauge item
			~ .guage-reading-item{
				position: relative;
				//top: 1.39em;
				@media screen and (max-width:499px){
					// removes the adjustment for all gauge reading items after the first large one
					// since they are on two lines on mobile
					~ .guage-reading-item{
						position: static;
					}
				}
			}

		}

		//mike - i removed this to as per client revision request
		/*@media screen and (max-width:500px){
			&:nth-child(2n+3){
				clear:left;
			}

		}*/


	}

}


.card.card-dam{
	@include clear;

	padding-bottom: 12px;
	.card-gauge-graph{
		width:46%;
		position: static;
		float:left;
		padding-left: 2.7em;
	}
	.card-gauge-right{
		width: 54%;
		float: right;
		text-align: center;
		padding-top: 0;
		padding-right: 3em;
		.guage-reading{
			margin-top:0;
			> :first-child{
				margin-top:0;

			}
		}

		.guage-reading-item{
			float:none;
			margin-right:0;
			margin-bottom:1em;
			position: static !important;

		}

		.gauge-reading-value{
			font-size:1em;
		}
	}
}

.gauge-station-links{
	.active{
		font-weight: bold;
	}
}

.guage-loading-placeholder{
	margin-top:1em;
	opacity:0.3;
	max-height: 200px;
	max-width:400px;
}