.feedback-widget-container {
    position: relative;
    width: 100%;
    max-width: 540px;
    margin-bottom: 3em; 
    margin-top: 1em;
    padding: 1em;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);

    @media screen and (min-width: 768px){ 
        width: 80%;
    }

    h3 {
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    legend {
        float: left;
        width: 100%;
        margin-right: 20px;
    }

    .radio-button-series {
        margin-bottom: 0;
        margin-top: 1em;

        .form-field {
            padding-left: 5px;
            label {
                font-size: 1em;
                font-weight: normal !important;
            }

            .radio-btn  {
                margin-right: 2em;
            }    
        }
    }

    fieldset {
        label {
            font-size: 1em;
            font-weight: normal;
        }

        textarea {
            width: 100%;
        }
    }

    iframe {
        width: 100%;
    }

    .report-btn {
        display: block;
        margin: 0;
        margin-top: 1em;

        @media screen and (min-width: 550px){ 
            position: absolute;
            right: 0;
            bottom: -10px; 
            margin-bottom: 1.6em;
            margin-right: 1em;
        }
    }

    svg {
        position: absolute;
        top: 20px;
        right: 0;
        font-size: 5em;

        @media screen and (min-width: 768px){
            font-size: 8em;
        }

       .icon {
                 
       } 
    }
}