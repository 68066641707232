
//Flood Colours
$flood-normal:#6DB345;
$flood-caution:#F1EB12;
$flood-alert:#F5881F;
$flood-warning:#E5242A;

$flood-default: #596061;

.flood-notice-banner{
    width:100%; 
    background-color:red; 
    
    color:white;
    display:flex;
    align-items: center;
    padding:1em 1em 1em 1.1em;
    position: relative;

    .flood-notice-banner-svg{
        // flex controls the width
        width:2em;
        flex: 0 0 2em;
        svg{
            display:block;
            width:2em;
            height:2em;
            fill:white;
            stroke: none;
        }
        svg path {
            fill: white;
            stroke: none;
        }
        & + .flood-notice-banner-text{
            padding-left:1.1em;
            position:relative;
            top:-0.05em;
        }

    }

    @media screen and (min-width: $slideout-goes-bigger){
        .notification-banners-1 & {
            padding-left:$slideout-left-padding-bigger;
        }
    }
    & + .flood-notice-banner{
        border-top:2px solid #454d4f;
    }

    //default status
    &.default-updating,
    &.default-normal,
    &.default-error{
        background-color:$flood-default;
    }

    // alert level green
    &.riverine-cancel,
    &.shoreline-cancel{
        background-color:$flood-normal;
    }


    // alert level yellow
    &.riverine-safety,
    &.riverine-outlook,
    &.bulletin,
    &.shoreline-shoreline_conditions_statement{
        background-color:$flood-caution;
        color:black;
        .flood-notice-banner-text a{
            color:black;
            &:hover{
                color:black;
                text-decoration: none;
            }
        }
        .flood-notice-banner-svg{
            svg path {
                fill: black;
            }
        }
    }

    // alret level oranage
    &.riverine-watch,
    &.shoreline-shoreline_watch{
        background-color:$flood-alert;
    }

    // alert level red
    &.riverine-warning,
    &.shoreline-shoreline_warning{
        background-color:$flood-warning;
    }

    .flood-notice-banner-text{
        margin:0;
        line-height: 1.1;
        max-width: 776px;
        a{
            color:white;
            text-decoration:underline;
            margin-left:0.5em;
            &:hover{
                color:black;
            }
        }
    }
}


.notification-banners{
    @media screen and (min-width: 1280px){
        display:flex;
        flex-wrap: wrap;
        .flood-notice-banner{
            flex:50%;

            & + .flood-notice-banner{
                border-top:0;
            }

            &:nth-child(2n){
                border-left:2px solid  #454d4f;
            }

            &:nth-child(n+3){
                border-top:2px solid  #454d4f;
            }
        }
    }
}