
// Colors

$brand-primary: #fdb913;
$brand-secondary: #8dc63f;
$brand-tertiary: #2babe2;



// Base Colours
$base-darkest: #282c2d;
$base-dark: #30383a;
$base-color: #454d4f;
$base-light: #e8eeef;
$base-lightest: white;

// Colour used for validation messages
$validation-color: #db0303;

$btn-out-of-range-color: #ff7b7b;

// Breakpoints
$bp-xxs: 320px;
$bp-xs: 480px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-lg: 1200px;
$bp-xl: 1500px;


//Layout

//Padding used on edges of the site
$wrap-small: 10px;
$wrap-med: 18px;

//When the med padding is applied
$wrap-med-bp: $bp-sm;

// Widest the site should go
$site-max-width: 1440px + ( $wrap-med * 2 );

// Widest the content will go
$content-max-width: 786px;


// Fonts
$font-primary: 'Lato', sans-serif;  //400,300,400italic,700,900
$font-secondary: 'Montserrat', sans-serif;  //700

// Animation transition time for hover
$trans-in: 50ms;
$trans-out: 200ms;

// Link color
$link-normal: $brand-tertiary;
// Link Hover
$link-hover:  darken($brand-tertiary, 25%);

// Break point for the menu to be full
$menu-go-big: 1024px;

// When the fonts will increase in size
$font-bp-one: $bp-lg;

//the menu
$menu-blocks: 6em;
$menu-block-lg: 8.9em;
$xl-header-padding:120px;


//How far the slideoutmenu will slide
$slideout-distance: 280px;
$slideout-goes-bigger: 1450px;
$slideout-disance-bigger: 512px;
$slideout-left-padding-bigger: 100px;


@mixin container-padding{
    padding-left: $wrap-small;
    padding-right:$wrap-small;

    @media screen and (min-width: $wrap-med-bp){
      padding-left:$wrap-med;
      padding-right:$wrap-med;
    }
}

//mixin used to make a container fluid have same sizes across site
@mixin container-fluid{

	max-width:$site-max-width;

	@include container-padding;

}

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}


@mixin flex-fit($child_class){

    display: flex;
    flex-wrap: wrap;

    &:before{
        display:none !important;
    }
    &:after{
        display:none  !important;
    }

    #{$child_class}{
        display:flex;
    }
}

.flex-fit{
    @include flex-fit('.flex-fit-item');
}


// applies to the parent grid
// assumes the structure is parent > row > item
// handles clearing items
// use margin top to manage space between items
// removes the margin off the bottom of the items so last row is flush
// removes margins off the top row of items so margin do not stack
@mixin make_seciton_columns($columns, $row_name, $col_name, $flex: false){

  @include clear;

  @if $flex == true{
    #{$row_name}{
      @include flex-fit($col_name); 
    }
  }

  #{$row_name}{
    @include make-row;
  }
  
  #{$col_name}{

    margin-top:$grid-gutter-width;

    > :first-child{
      margin-top:0;
    }

    > :last-child{
      margin-bottom:0;
    }

    @include make-column($columns);

    $items_at_top: floor($grid-columns / $columns); 
    &:nth-child(#{$items_at_top}n+1){
      clear:left;
    }
    
    &:nth-child(-n+#{$items_at_top}){
      margin-top:0;
    }
  }

}


/*
The .flex-min-height-fix wrapper is added to fix a known issue in IE: 
https://connect.microsoft.com/IE/feedback/details/816293/ie11-flexbox-with-min-height-not-vertically-aligning-with-align-items-center
https://codepen.io/chriswrightdesign/pen/emQNGZ/
*/
.flex-min-height-fix{
    //by having a parent with flex-direction:row, the min-height bug in IE doesn't stick around.
  display:flex;
  flex-direction:row;
  > *{
    width:100%;
  }
}