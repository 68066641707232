.precip-bar-legend-container {
    border-radius: 3px;
    padding: 5px;
    padding-left: 8px;
    background-color: rgba(255,255,255,0.8);
    -webkit-box-shadow: 2px 2px 5px 0px rgba(179,175,179,0.34);
    -moz-box-shadow: 2px 2px 5px 0px rgba(179,175,179,0.34);
    box-shadow: 2px 2px 5px 0px rgba(179,175,179,0.34);
    z-index: 5;

    @media screen and ( min-width:500px ) {
        position: absolute;
        right: 61px;
        bottom: 22px;
    } 

    .radio-button-series {
        margin: 0;
        legend {
            margin-bottom: 10px;
        }

        .form-field {
            label {
                margin-right: 10px;
                font-size: 14px;

                input {
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    margin-right: 3px;
                }

            }
        }
    }
}
